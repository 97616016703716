import React, { useEffect, useState } from "react";
import { API_URL, DEFAULT_ERROR } from "../common/constants";
import axios from "../api/axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";

const ViewPatiantDetails = () => {

    const params = useParams();
    const _D_ID = params?._id

    const [details, setDetails] = useState({
        patient_details: {},
        surgical_details: {}
    })

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        axios.get(`${API_URL.VIEW_PATIANT_DETAILS}/${_D_ID}`)
            .then((response => {
                setIsLoading(false)
                if (response?.data?.data) {
                    setDetails(response?.data?.data)
                } else if (typeof response?.data?.data === 'string') {
                    toast.error(response?.data?.data)
                }
                else {
                    toast.error(DEFAULT_ERROR)
                }

            })).catch((err) => {
                setIsLoading(false)
                if (err?.response) {
                    toast.error(err?.response?.data?.data?.message || DEFAULT_ERROR)
                };
            })
    },[_D_ID])

    return (
        <div className="card-body">
            {isLoading ? <div className="center-screen"> <ThreeDots color="#0000cd" /> </div> : <>
                <hr className="my-4" />
                <h6 className="heading-small text-muted mb-4">Patient Details</h6>
                <hr className="my-4" />
                <div className="pl-lg-4">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="form-group focused">
                                <label className="form-control-label" for="patiantInitials" >Patient Initials</label>
                                <input disabled={true} type="text" id="patiantInitials" className="form-control form-control-alternative" placeholder="Patient Initals" value={details?.patient_details?.patient_initials} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label className="form-control-label" for="patiantDob">Patient DOB</label>
                                <input disabled={true} type="text" id="patiantDob" className="form-control form-control-alternative" placeholder="Patient DOB" value={details?.patient_details?.patient_DOB} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="form-group focused">
                                <label className="form-control-label" for="hairColor">Hair Color</label>
                                <input disabled={true} type="text" id="hairColor" className="form-control form-control-alternative" placeholder="Hair Color" value={details?.patient_details?.hair_color} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group focused">
                                <label className="form-control-label" for="hairCaliber">Hair Caliber</label>
                                <input disabled={true} type="text" id="hairCaliber" className="form-control form-control-alternative" placeholder="Hair Caliber" value={details?.patient_details?.hair_caliber} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="form-group focused">
                                <label className="form-control-label" for="hairType">Hair Type</label>
                                <input disabled={true} type="text" id="hairType" className="form-control form-control-alternative" placeholder="Hair Type" value={details?.patient_details?.hair_type} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group focused">
                                <label className="form-control-label" for="skinColor">Skin Color</label>
                                <input disabled={true} type="text" id="skinColor" className="form-control form-control-alternative" placeholder="Skin Color" value={details?.patient_details?.skin_color} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="form-group focused">
                                <label className="form-control-label" for="dateOfSX">Date Of SX</label>
                                <input disabled={true} type="text" id="dateOfSX" className="form-control form-control-alternative" placeholder="Date Of SX" value={details?.patient_details?.date_of_sx} />
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="my-4" />
                <h6 className="heading-small text-muted mb-4">Surgeon Details</h6>
                <hr className="my-4" />
                <div className="pl-lg-4">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="form-group focused">
                                <label className="form-control-label" for="surgeon" >Surgeon</label>
                                <input disabled={true} type="text" id="surgeon" className="form-control form-control-alternative" placeholder="Surgeon" value={details?.surgical_details?.surgeon} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label className="form-control-label" for="surgicalGoal">Surgical Goal</label>
                                <input disabled={true} type="text" id="surgicalGoal" className="form-control form-control-alternative" placeholder="Surgical Goal" value={details?.surgical_details?.surgical_goal} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="form-group focused">
                                <label className="form-control-label" for="fueDeviceUsed">FUE Device Use</label>
                                <input disabled={true} type="text" id="fueDeviceUsed" className="form-control form-control-alternative" placeholder="FUE Device Use" value={details?.surgical_details?.device_used} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group focused">
                                <label className="form-control-label" for="fueTipStyle">FUE Tip Style</label>
                                <input disabled={true} type="text" id="fueTipStyle" className="form-control form-control-alternative" placeholder="FUE Tip Style" value={details?.surgical_details?.tip_style} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="form-group focused">
                                <label className="form-control-label" for="fueTipSize">FUE Tip Size</label>
                                <input disabled={true} type="text" id="fueTipSize" className="form-control form-control-alternative" placeholder="FUE Tip Size" value={details?.surgical_details?.tip_size} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group focused">
                                <label className="form-control-label" for="holdingSolution">Holding Solution</label>
                                <input disabled={true} type="text" id="holdingSolution" className="form-control form-control-alternative" placeholder="Holding Solution" value={details?.surgical_details?.holding_solution} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="form-group focused">
                                <label className="form-control-label" for="placingDeviceUsed">Placing Device Used</label>
                                <input disabled={true} type="text" id="placingDeviceUsed" className="form-control form-control-alternative" placeholder="Placing Device Used" value={details?.surgical_details?.placing_device_used} />
                            </div>
                        </div>
                    </div>
                </div>
            </>}
        </div>
    );
};

export default ViewPatiantDetails;