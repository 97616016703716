import React, { useState } from "react";
import '../styles/dashboard.css'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { clearCookie } from "../common/cookie";
import { LOGOUT } from "../actions/types";
import { NAVIGATE_LNK, USER_TYPE_CONSTANT } from "../common/constants";

const Navbar = () => {
    const navigate = useNavigate()

    const dispatch = useDispatch()
    const user = useSelector((e) => e.auth.user);
    const userType = user?.userType

    const [isToggle, setIsToggle] = useState(false)

    const handle = {
        logout: () => {
            dispatch({ type: LOGOUT })
            clearCookie()
        },
        openNavbar: () => {
            const navs = document.querySelectorAll('.collapse')
            navs.forEach(nav => nav.classList.toggle('show'));
        },
        changeRoute: (path) => {
            const navs = document.querySelectorAll('.collapse')
            navs.forEach(nav => nav.classList.toggle('show'));
            navigate(path)
        },
        toggle: () => {
            // setIsToggle(prev => !prev);
            const navs = document.getElementById('navbarVertical')
            navs.classList.toggle('display-none');
        }
    }
    return (
        <>
            {/* <!-- Vertical Navbar --> */}
            {!isToggle && <nav className="navbar show navbar-vertical h-lg-screen navbar-expand-lg px-0 py-3 navbar-light bg-white border-bottom border-bottom-lg-0 border-end-lg" id="navbarVertical">
                <div className="container-fluid">
                    {/* <!-- Toggler --> */}
                    <button className="navbar-toggler ms-n2" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation" onClick={handle.openNavbar}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <a className="navbar-brand py-lg-2 mb-lg-5 px-lg-6 me-0" href="# ">
                        <h3>Surgery Tracker</h3>
                    </a>
                    <div className="navbar-user d-lg-none">
                    </div>
                    {/* <!-- Collapse --> */}
                    <div className="collapse navbar-collapse" id="sidebarCollapse">
                        {/* <!-- Navigation --> */}
                        <ul className="navbar-nav">
                        {userType !== USER_TYPE_CONSTANT.TECHNICIAN && <li className="nav-item cursor-pointer">
                                <a className="nav-link" onClick={() => handle.changeRoute(NAVIGATE_LNK.DASHBOARD) } href="# ">
                                    <i className="bi bi-house"></i> Dashboard
                                </a>
                            </li>}
                            {userType !== USER_TYPE_CONSTANT.TECHNICIAN && <li className="nav-item cursor-pointer">
                                <a className="nav-link" onClick={() => handle.changeRoute(NAVIGATE_LNK.SETTING)} href="# ">
                                    <i className="bi bi-gear"></i> Settings
                                </a>
                            </li>}
                            {userType === USER_TYPE_CONSTANT.ADMIN && <li className="nav-item cursor-pointer">
                                <a className="nav-link" onClick={() => handle.changeRoute(NAVIGATE_LNK.DOCTOR_LIST) } href="# ">
                                    <i className="bi bi-people"></i> Doctors
                                </a>
                            </li>}

                            {userType !== USER_TYPE_CONSTANT.TECHNICIAN && <li className="nav-item cursor-pointer">
                                <a className="nav-link" onClick={() => handle.changeRoute(NAVIGATE_LNK.TECHNICIAN_LIST) } href="# ">
                                    <i className="bi bi-people"></i> Technicians
                                </a>
                            </li>}
                            {userType !== USER_TYPE_CONSTANT.TECHNICIAN && <li className="nav-item cursor-pointer">
                                <a className="nav-link" onClick={() => handle.changeRoute(NAVIGATE_LNK.PATIANT_DETAIL)}   href="# ">
                                    <i className="bi bi-bar-chart"></i> Surgery Tracker
                                </a>
                            </li>}
                            {true && <li className="nav-item cursor-pointer">
                                <a className="nav-link" onClick={() => handle.changeRoute(NAVIGATE_LNK.PATIANT_DETAIL_LIST) } href="# ">
                                    <i className="bi bi-people"></i> Previous Surgeries
                                </a>
                            </li>}
                            {userType === USER_TYPE_CONSTANT.TECHNICIAN && <li className="nav-item cursor-pointer">
                                <a className="nav-link" onClick={() => handle.changeRoute(NAVIGATE_LNK.GRAFT_COUNT_LIST)}   href="# ">
                                    <i className="bi bi-bar-chart"></i> Graft Count List
                                </a>
                            </li>}

                            <li className="nav-item cursor-pointer">
                                <a className="nav-link" onClick={handle.logout } href="# ">
                                    <i className="bi bi-box-arrow-left"></i> Logout
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>}
            <button className="sticky-top-left" onClick={handle.toggle}> <i className="bi-list"></i> </button>
        </>
    );
};

export default Navbar;