import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import Home from "./pages/Home";
import PatiantDetail from "./pages/PatiantDetail";
import './App.css';
import { useEffect, useState } from "react";
import { clearCookie, createCookie, getCookie } from "./common/cookie";
import axios from "./api/axios";
import { API_URL, NAVIGATE_LNK, USER_TYPE_CONSTANT } from "./common/constants";
import { useDispatch, useSelector } from "react-redux";
import { LOGIN_SUCCESS, LOGOUT } from "./actions/types";
import { ThreeDots } from 'react-loader-spinner';
import Dashboard from "./pages/Dashboard";
import Navbar from "./component/Navbar";
import DocterList from "./pages/DocterList";
import TechnicianList from "./pages/TechnicianList";
import AddDoctor from "./pages/AddDoctor";
import AddTechnician from "./pages/AddTechnician";
import { ToastContainer } from "react-toastify";
import PatiantDetailList from "./pages/PatiantDetailList";
import ViewPatiantDetails from "./pages/ViewPatiantDetail";
import PageNotFound from "./component/PageNotFound";
import GraftCount from "./pages/GraftCount";
import SumuryReport from "./pages/SumuryReport";
import GraftCountList from "./pages/GraftCountList";
import ReactTooltip from 'react-tooltip';
import SurgicalDashboard from "./pages/SurgicalDashboard";
import TechByData from "./pages/TechByData";
import Setting from "./pages/Setting";

function App() {

  const _IA = useSelector((e) => e.auth.isAuthenticated);
  const user = useSelector((e) => e.auth.user);
  const userType = user?.userType

  const dispatch = useDispatch();


  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    const accessToken = getCookie();
    if (accessToken) {
      axios.post(API_URL.LOGIN, { accessToken }).then(response => {
        const accessToken = response?.data?.data?.accessToken;
        createCookie(accessToken)
        const user = {
          _id: response?.data?.data?._id,
          userType: response?.data?.data?.userType,
          email: response?.data?.data?.email,
          userName: response?.data?.data?.userName
        }
        dispatch({ type: LOGIN_SUCCESS, payload: { user } })
        setIsLoading(false)
      }).catch(err => {
        setIsLoading(false)
        if (err.response) {
          dispatch({ type: LOGOUT })
          clearCookie()
        }
      })
    }
    else {
      setIsLoading(false)
    }
  }, [dispatch])

  const AccessForbidden = () => <PageNotFound title={"Access Forbidden"} warning={"Look like you don't have access"} />

  return (
    <>

      <div className="App">
        {isLoading && <div className="center-screen"> <ThreeDots color="#0000cd" /> </div>}
        {!isLoading && <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
          <Router>
            {!isLoading && _IA && <Navbar />}
            <Routes>
              <Route exect path="/" element={<Login />}></Route>
              <Route exect path={NAVIGATE_LNK.PATIANT_DETAIL} element={_IA ? userType !== USER_TYPE_CONSTANT.TECHNICIAN ? <PatiantDetail /> : <AccessForbidden /> : !isLoading && <Navigate replace to="/" />} />
              <Route exect path={`${NAVIGATE_LNK.PATIANT_DETAIL}/:_id`} element={_IA ? userType !== USER_TYPE_CONSTANT.TECHNICIAN ? <PatiantDetail /> : <AccessForbidden /> : !isLoading && <Navigate replace to="/" />} />
              <Route exect path={NAVIGATE_LNK.DASHBOARD} element={_IA ? userType !== USER_TYPE_CONSTANT.TECHNICIAN ? <Dashboard /> : <AccessForbidden /> : !isLoading && <Navigate replace to="/" />} />
              <Route exect path={NAVIGATE_LNK.SETTING} element={_IA ? userType !== USER_TYPE_CONSTANT.TECHNICIAN ? <Setting /> : <AccessForbidden /> : !isLoading && <Navigate replace to="/" />} />
              <Route exect path={NAVIGATE_LNK.HOME} element={_IA ? <Home /> : !isLoading && <Navigate replace to="/" />} />
              <Route exect path={NAVIGATE_LNK.DOCTOR_LIST} element={_IA ? userType === USER_TYPE_CONSTANT.ADMIN ? <DocterList /> : <AccessForbidden /> : !isLoading && <Navigate replace to="/" />} />
              <Route exect path={NAVIGATE_LNK.TECHNICIAN_LIST} element={_IA ? userType !== USER_TYPE_CONSTANT.TECHNICIAN ? <TechnicianList /> : <AccessForbidden /> : !isLoading && <Navigate replace to="/" />} />
              <Route exect path={`${NAVIGATE_LNK.TECH_BY_DATA}/:_id`} element={_IA ? userType !== USER_TYPE_CONSTANT.TECHNICIAN ? <TechByData /> : <AccessForbidden /> : !isLoading && <Navigate replace to="/" />} />
              <Route exect path={NAVIGATE_LNK.ADD_TECHNICIAN} element={_IA ? userType !== USER_TYPE_CONSTANT.TECHNICIAN ? <AddTechnician /> : <AccessForbidden /> : !isLoading && <Navigate replace to="/" />} />
              <Route exect path={NAVIGATE_LNK.ADD_DOCTOR} element={_IA ? userType === USER_TYPE_CONSTANT.ADMIN ? <AddDoctor /> : <AccessForbidden /> : !isLoading && <Navigate replace to="/" />} />
              <Route exect path={NAVIGATE_LNK.PATIANT_DETAIL_LIST} element={_IA ? <PatiantDetailList /> : !isLoading && <Navigate replace to="/" />} />
              <Route exect path={`${NAVIGATE_LNK.VIEW_PATIANT_DETAILS}/:_id`} element={_IA ? true ? <ViewPatiantDetails /> : <AccessForbidden /> : !isLoading && <Navigate replace to="/" />} />
              <Route exect path={`${NAVIGATE_LNK.GRAFT_COUNT}/:_id`} element={_IA ? true ? <GraftCount /> : <AccessForbidden /> : !isLoading && <Navigate replace to="/" />} />
              <Route exect path={`${NAVIGATE_LNK.GRAFT_COUNT}/:_id/:techId`} element={_IA ? true ? <GraftCount /> : <AccessForbidden /> : !isLoading && <Navigate replace to="/" />} />
              <Route exect path={`${NAVIGATE_LNK.SUMURY_REPORT}/:_id`} element={_IA ? true ? <SumuryReport /> : <AccessForbidden /> : !isLoading && <Navigate replace to="/" />} />
              <Route exect path={`${NAVIGATE_LNK.GRAFT_COUNT_LIST}`} element={_IA ? userType === USER_TYPE_CONSTANT.TECHNICIAN ? <GraftCountList /> : <AccessForbidden /> : !isLoading && <Navigate replace to="/" />} />
              <Route exect path={`${NAVIGATE_LNK.SURGICAL_DASHBOARD}/:_id`} element={_IA ? userType !== USER_TYPE_CONSTANT.TECHNICIAN ? <SurgicalDashboard /> : <AccessForbidden /> : !isLoading && <Navigate replace to="/" />} />
              <Route exect path="*" element={<PageNotFound />} />
            </Routes>
          </Router>

          <ToastContainer limit={2} />
          <ReactTooltip />
        </div>}
      </div>
    </>

  );
}

export default App;
