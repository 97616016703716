import React, { useState } from "react";
import { useSelector } from "react-redux";
import { API_URL, DEFAULT_ERROR, NAVIGATE_LNK, USER_TYPE_CONSTANT } from "../common/constants";
import '../styles/addUser.css'
import axios from "../api/axios";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { ThreeDots } from "react-loader-spinner";

const AddDoctor = () => {
    const navigate = useNavigate()
    
    const user = useSelector((e) => e.auth.user);

    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [inputText, setInputText] = useState({
        name: "",
        email: "",
        password: "",
        image: ""
    })
    const [image, setImage] = useState([]);

    const handle = {

        submit: (e) => {
            e.preventDefault();
            if (isLoading || user?.userType !== USER_TYPE_CONSTANT.ADMIN) return
            setError("")
            if (inputText.name?.length < 3) {
                setError("Name must be greater then or equal to three character!");
            } else if (inputText.password?.length < 6) {
                setError("Password must be greater then or equal to six character!");
            }
            else if (inputText.email?.length < 3) {
                setError("Please enter a valid email!");
            } else {
                setIsLoading(true)

                const requestData = {
                    userName: inputText?.name,
                    password: inputText?.password,
                    email: inputText?.email,
                    userType: parseInt(USER_TYPE_CONSTANT.DOCTOR),
                    image,
                    parentId: user?._id
                }

                let formData = new FormData();  //append the values with key, value pair
                // formData.append(...requestData);
                formData.append('image', image);
                formData.append('userName', requestData.userName);
                formData.append('password', requestData.password);
                formData.append('email', requestData.email);
                formData.append('userType', parseInt(requestData.userType));
                formData.append('parentId', requestData.parentId);
                
                const config = {
                    headers: { 'content-type': 'multipart/form-data' }
                }
                axios.post(API_URL.SIGNUP, formData, config ) // requestData
                    .then((response => {
                        setInputText({
                            name: "",
                            email: "",
                            password: "",
                            image: ""
                        });
                        setIsLoading(false)
                        if (response?.data?.data?._id) {
                            navigate(NAVIGATE_LNK.DOCTOR_LIST);
                            toast.success("Doctor added successfully")
                        } else if (typeof response?.data?.data === 'string') {
                            toast.error(response?.data?.data)
                            // setError(response?.data?.data)
                        }
                        else {
                            setError(DEFAULT_ERROR)
                        }

                    })).catch((err) => {
                        setInputText({
                            name: "",
                            email: "",
                            password: "",
                            image: ""
                        });
                        setIsLoading(false)
                        if (err?.response) {
                            toast.error(err?.response?.data?.data?.message)
                            // setError(err?.response?.data?.data?.message || DEFAULT_ERROR)
                        };
                    })
            }

        },
        inputEvent: (event) => {
            const { name, value } = event.target;
            setError("")
            setInputText((oldValues) => {
                return {
                    ...oldValues,
                    [name]: value
                }

            });
        },
        selectFile: (event) => {
            setError("")
            setImage(event.target.files[0])
        }
    }

    return (
        <>
            <div className="container">
                <h1> Add Doctor</h1>
                {isLoading && <div className="center-screen"> <ThreeDots color="#0000cd" /> </div>}
                {!isLoading && <form id="contact" method="post" action="">

                    <small className="error">{error}</small>

                    <div className="line">
                        <label for="name">Name: </label>
                        <input type="text" name="name" id="name" onChange={handle.inputEvent} value={inputText.name} />
                    </div>


                    <div className="line">
                        <label for="email">E-Mail: </label>
                        <input type="email" name="email" id="email" onChange={handle.inputEvent} value={inputText.email} />
                    </div>


                    <div className="line">
                        <label for="password">Password: </label>
                        <input type="password" name="password" id="password" onChange={handle.inputEvent} value={inputText.password} />
                    </div>
                    
                    <div className="line">
                        <label for="image"className="mr-10">Logo: </label>
                        <input type="file" name="image" id="image" accept="image/*" onChange={handle.selectFile}/>
                    </div>

                    <div className="line">
                        <a className="btn d-inline-flex btn-sm btn-primary mx-1" onClick={handle.submit}>
                            <span className=" pe-2">
                                <i className="bi bi-plus"></i>
                            </span>
                            <span>Add Doctor</span>
                        </a>
                    </div>


                </form>}
            </div>
        </>
    );
};

export default AddDoctor;