import React, { useEffect, useState } from "react";
import { API_URL, DEFAULT_ERROR } from "../common/constants";
import axios from "../api/axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import PageNotFound from "../component/PageNotFound";
import ViewPatiantDetails from "./ViewPatiantDetail";
import { useSocket } from "../hooks/useSocket";

const SumuryReport = () => {
    const { _id } = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [list, setList] = useState([])
    const [totalList, setTotalList] = useState({})

    const [columnList, setColumnList] = useState([]);
    const [graftCount, setGraftCount] = useState({});
    const totalColumn = [{ name: "Graft Count", value: 'total_grafts' }, { name: "Hair Count", value: 'total_hairCont' }, { name: "Pot Hair", value: 'total_PotentialHair' }, { name: "Trans Rate Hair", value: 'transaction_rate_hair' }, { name: "Trans Rate Graft", value: 'transaction_rate_grafts' }];

    const { on, off, refresh } = useSocket((res, event) => {
        console.log(res, event)
        switch (event) {
            case "getHairRecord":
                if (res) {
                    let { patient_id, data } = res;
                    if (patient_id === _id) {
                        setList(data?.allTechRecords)
                        let allTechTotal = {}
                        data?.allTechTotalRecords?.map(e => allTechTotal[e?.tech_name] = e);
                        setTotalList(allTechTotal)
                    }
                }
                break;
            default:
                break;
        }
    })

    useEffect(() => {
        refresh()
        return () => off()
    }, [])

    useEffect(() => {
        setIsLoading(true)
        axios.get(`${API_URL.SUMURY_REPORT}/${_id}`)
            .then((response => {
                setIsLoading(false)
                if (response?.data?.data) {
                    if (response?.data?.data?.allTechRecords) setList(response?.data?.data?.allTechRecords)
                    else setList(response?.data?.data)
                    let allTechTotal = {}
                    response?.data?.data?.allTechTotalRecords?.map(e => allTechTotal[e?.tech_name] = e);
                    setTotalList(allTechTotal)
                } else if (typeof response?.data?.data === 'string') {
                    toast.error(response?.data?.data)
                }
                else {
                    toast.error(DEFAULT_ERROR)
                }

            })).catch((err) => {
                setIsLoading(false)
                if (err?.response) {
                    toast.error(err?.response?.data?.data?.message || DEFAULT_ERROR)
                };
            })
    }, [_id])

    useEffect(() => {
        let c = new Set();
        let g = {}
        list?.map(e => {
            c?.add(e?.tech_name || 'other');
            e?.hairCount_details?.map(l => {
                if (g[`${l?.x}/${l?.y}`]) g[`${l?.x}/${l?.y}`] = { ...g[`${l?.x}/${l?.y}`], [e?.tech_name || 'other']: l?.count }
                else g[`${l?.x}/${l?.y}`] = { [e?.tech_name || 'other']: l?.count }
                return ""
            })
            return ""
        });
        setColumnList([...c]);
        setGraftCount(g)
    }, [list])

    return (
        <div className="container-fluid">
            {isLoading ? <div className="center-screen"> <ThreeDots color="#0000cd" /> </div> :
                columnList?.length ? <>
                    <ViewPatiantDetails />
                    <div className="line">
                        <span>Summary Report</span>
                    </div>
                    <table>
                        <tr>
                            <th>Graft Type</th>
                            {columnList.map((e, i) => {
                                return (<th>{e}</th>)
                            })}
                        </tr>

                        {Object.keys(graftCount)?.map((key, i) => {
                            return (<>
                                <tr data-ng-repeat="customer in people | filter: table">
                                    <td>{key}</td>
                                    {columnList.map((e, i) => {
                                        return (<td>{graftCount[key][e] || "-"}</td>)
                                    })}
                                </tr>
                            </>)
                        })}

                    </table>
                    {Object.keys(totalList)?.length > 0 && <table>
                        <tr>
                            <th></th>
                            {totalColumn.map((e, i) => {
                                return (<th>{e?.name}</th>)
                            })}
                        </tr>

                        {Object.keys(totalList)?.map((key, i) => {
                            return (<>
                                <tr data-ng-repeat="customer in people | filter: table">
                                    <td>{key}</td>
                                    {totalColumn.map((e, i) => {
                                        return (<td>{totalList?.[key]?.[e?.value] || "-"}</td>)
                                    })}
                                </tr>
                            </>)
                        })}

                    </table>}
                </> : <PageNotFound title={"No Data Found!"} warning={"Look like you don't have any data"} text=" " />
            }


        </div>
    );
};

export default SumuryReport;