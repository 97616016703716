import React from "react";
import { FormControl, InputLabel, MenuItem, Select as Selector } from "@mui/material";

const Select = ({ id, label, value, onChange, options, className }) => {

    return (
        <FormControl className={className}>
            <InputLabel id={id}>{label}</InputLabel>
            <Selector
                labelId={id}
                id={id}
                value={value}
                onChange={onChange}
                autoWidth
                label={label}
                name={id}
            >
                {options?.map(e => {
                    return (
                        <MenuItem value={e?.value}>{e?.name}</MenuItem>
                    )
                })}
            </Selector>
        </FormControl>
    );
};

export default Select;