import React from "react";
import { useNavigate } from "react-router-dom";
import { NAVIGATE_LNK } from "../common/constants";
import '../styles/pageNotFound.css'
const PageNotFound = (props) => {


    const navigate = useNavigate()

    const goToDashboard = () => {
        navigate(NAVIGATE_LNK.DASHBOARD)
    }

    return (
        <div className="container">
            <div className="col-sm-12 ">
                <div className="col-sm-10 col-sm-offset-1  text-center">
                    <div className="four_zero_four_bg">
                        <h1 className="text-center ">{props?.title || "404 Page Not Found"}</h1>
                    </div>

                    <div className="contant_box_404">
                        <h3 className="h2">
                            {props?.warning || "Look like you're lost"}
                        </h3>

                        <p>{props.text || "the page you are looking for not avaible!"}</p>

                        {/* <button onClick={goToDashboard} className="link_404">Go to Dashboad</button> */}
                    </div>
                </div>
            </div>
        </div>


    );
};

export default PageNotFound;