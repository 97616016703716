import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "../api/axios";
import { API_URL, DEFAULT_ERROR } from "../common/constants";
import Model from "../component/Model";
import PageNotFound from "../component/PageNotFound";
import { Alert } from "../component/Popup";

const GraftCountList = () => {

    const user = useSelector(e => e.auth.user);

    const [isLoading, setIsLoading] = useState(false);
    const [list, setList] = useState([])
    const [modelData, setModelData] = useState({ columns: [], rows: [], open: false })


    const handle = {
        delete: (_id) => {
            Alert.show({
                title: "Are you sure?",
                message: "Do you want to delete this doctor?",
                onSubmit: () => {
                    setIsLoading(true)
                    const requestData = {
                        patientId: _id
                    }
                    axios.delete(`${API_URL.DELETE_GRAFT_COUNT}/${user?._id}`, { data: requestData })
                        .then((response => {
                            setIsLoading(false)
                            if (response?.data?.data?.message) {
                                toast.success(response?.data?.data?.message)
                                fetchList();
                            } else if (typeof response?.data?.data === 'string') {
                                toast.error(response?.data?.data)
                            }
                            else {
                                toast.error(DEFAULT_ERROR)
                            }

                        })).catch((err) => {
                            setIsLoading(false)
                            if (err?.response) {
                                toast.error(err?.response?.data?.data?.message || DEFAULT_ERROR)
                            };
                        })
                }
            });
        },
        view: (data) => {
            const columns = [
                { id: 'type', label: 'Type', minWidth: 170 },
                { id: 'count', label: 'Count', minWidth: 170 },
            ]
            const rows = data?.map(e => ({ type: `${e?.x}/${e?.y}`, count: e?.count }));
            setModelData({ columns, rows, open: true })
        }
    }

    const fetchList = () => {
        axios.get(`${API_URL.GRAFT_COUNT_LIST}/${user._id}`)
            .then((response => {
                setIsLoading(false)
                if (response?.data?.data) {
                    setList(response?.data?.data)
                } else if (typeof response?.data?.data === 'string') {
                    toast.error(response?.data?.data)
                }
                else {
                    toast.error(DEFAULT_ERROR)
                }

            })).catch((err) => {
                setIsLoading(false)
                if (err?.response) {
                    toast.error(err?.response?.data?.data?.message || DEFAULT_ERROR)
                };
            })
    }
    useEffect(() => {
        setIsLoading(true)
        fetchList()
    }, [])

    return (
        <>
            <div className="container-fluid">
                <div className="line">
                    <span>Graft Count List</span>
                </div>
                {isLoading ? <div className="center-screen"> <ThreeDots color="#0000cd" /> </div> :
                    list?.length ? <> <table>
                        <tr>
                            <th>No</th>
                            <th>Patient Name</th>
                            <th>View</th>
                            <th>Delete</th>
                        </tr>
                        {list.map((e, i) => {
                            return (<>
                                <tr id={e?._id} data-ng-repeat="customer in people | filter: table">
                                    <td>{i + 1}</td>
                                    <td>{e?.patientName}</td>
                                    <td>
                                        <i className="bi bi-eye cursor-pointer" onClick={() => { handle.view(e?.hairCount_details) }}></i>
                                    </td>
                                    <td>
                                        <i className="bi bi-trash cursor-pointer" onClick={() => { handle.delete(e?.patient_id) }}></i>
                                    </td>
                                </tr>
                            </>)
                        })}
                    </table>
                    </> : <PageNotFound title={"No Data Found!"} warning={"Look like you don't have any data"} text=" " />
                }


            </div>

            {modelData.open && <Model columns={modelData.columns} rows={modelData.rows} open={modelData.open} onClose={() => setModelData({ columns: [], rows: [], open: false })} />}
        </>
    );
};

export default GraftCountList;