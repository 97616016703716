import React from "react";
import { clearCookie } from "../common/cookie";
import { useDispatch, useSelector } from "react-redux";
import { LOGOUT } from "../actions/types";
import { USER_TYPE } from "../common/constants";

const Home = () => {

  const dispatch = useDispatch()
  const user = useSelector((e) => e.auth.user);

  const handle = {
    logout: ()=>{
      dispatch({ type: LOGOUT })
      clearCookie()
    }
  }

  return (
    <div>
      <h1>Home</h1>
      <h6>Email : {user?.email}</h6>
      <h6>User Type : {USER_TYPE[user?.userType]}</h6>
      <div className="submit_button">
            <button type="button" onClick={handle.logout}><span>Logout</span><i className="fa fa-long-arrow-right"></i></button>
      </div>
    </div>
  );
};

export default Home;