import { useEffect, useState } from "react";
import '../styles/login.css'
import axios from '../api/axios';
import { clearCookie, createCookie } from "../common/cookie";
import { API_URL, NAVIGATE_LNK, USER_TYPE_CONSTANT } from "../common/constants";
import { useDispatch, useSelector } from "react-redux";
import { LOGIN_FAIL, LOGIN_SUCCESS } from "../actions/types";
import { Navigate } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';

const Login = () => {
  const dispatch = useDispatch();

  const _IA = useSelector((e) => e.auth.isAuthenticated);
  const user = useSelector((e) => e.auth.user);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [inputText, setInputText] = useState({
    name: "",
    password: ""
  });



  useEffect(() => {
    setError('');
  }, [inputText])

  const submitForm = async (e) => {
    e.preventDefault();
    setError("");
    if (inputText.name === "") {
      setError("User name is mandatory field!");
    } else if (inputText.password === "") {
      setError("Password is mandatory field!");
    } else {
      try {
        setIsLoading(true)
        const response = await axios.post(API_URL.LOGIN, { email: inputText?.name, password: inputText?.password });
        setIsLoading(false)
        const accessToken = response?.data?.data?.accessToken;
        createCookie(accessToken)
        setInputText({
          userName: "",
          password: ""
        });
        const user = {
          _id: response?.data?.data?._id,
          userType: response?.data?.data?.userType,
          email: response?.data?.data?.email,
          userName: response?.data?.data?.userName
        }
        dispatch({ type: LOGIN_SUCCESS, payload: { user } })
      } catch (err) {

        setIsLoading(false)
        if (err?.response) {
          setError(err?.response?.data?.data?.message || 'Login Failed')
          dispatch({ type: LOGIN_FAIL })
          clearCookie()
        };
      }
    }

  }

  const inputEvent = (event) => {
    const { name, value } = event.target;
    setInputText((oldValues) => {
      return {
        ...oldValues,
        [name]: value
      }

    });
  }

  if (_IA) {
    return <Navigate to={`${user?.userType === USER_TYPE_CONSTANT.TECHNICIAN ? NAVIGATE_LNK.PATIANT_DETAIL_LIST : NAVIGATE_LNK.DASHBOARD}`} />;
  }


  return (
    <>{
      <div className="container">
        {!isLoading && <h1> Login</h1>}
        {isLoading ? <div className="center-screen"> <ThreeDots color="#0000cd" /> </div> :
          <form id="contact" method="post" action="">

            <small className="error">{error}</small>

            <div className="line">
              <label>User name:</label>
              <input type="text" name="name" id="name" onChange={inputEvent} value={inputText.name} />
            </div>

            <div className="line">
              <label>Password: </label>
              <input type="password" name="password" id="password" onChange={inputEvent} value={inputText.password} />
            </div>

            <div className="line">
              <button className="btn d-inline-flex btn-sm btn-primary mx-1" onClick={submitForm}>
                <span>Login </span>
              </button>
            </div>


          </form>
        }
      </div>}
    </>
  );
};

export default Login;