export const createCookie = (accessToken) => {
    localStorage.setItem('surgery_token', accessToken);
}

export const getCookie = () => {
    return localStorage.getItem('surgery_token');
}

export const clearCookie = () => {
    return localStorage.removeItem('surgery_token');
}
