import React, { useEffect, useState } from "react";
import '../styles/crud.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from "react-toastify";
import axios from "../api/axios";
import { DEFAULT_ERROR } from "../common/constants";



const Crud = (props) => {

    const columns = props.columns;
    const [rows, setRows] = useState([]);
    const [callApi, setCallApi] = useState(false)

    const handle = {
        onAddNew: () => {
            const row = JSON.parse(JSON.stringify(rows));
            const obj = { disabled: false }
            columns?.map(e => {
                obj[e?.value] = ""
            });
            row.push(obj)
            setRows(row)
        },
        onEdit: (i, _id, isSave) => {
            if(isSave){
                if(_id){
                    axios.put(`${props.updateUrl}/${_id}`, {name:rows[i]?.name})
                    .then((response => {
                        if (response?.data?.data?.message) {
                            toast.success(response?.data?.data?.message)
                        } else if (typeof response?.data?.data === 'string') {
                            toast.error(response?.data?.data)
                        }
                        else {
                            toast.error(DEFAULT_ERROR)
                        }
        
                    })).catch((err) => {
                        if (err?.response) {
                            toast.error(err?.response?.data?.data?.message || DEFAULT_ERROR)
                        };
                    })
                }
                else{
                    axios.post(`${props.addUrl}`, {name:rows[i]?.name})
                    .then((response => {
                        if (response?.data?.data?.message) {
                            setCallApi(!callApi)
                            toast.success(response?.data?.data?.message)
                        } else if (typeof response?.data?.data === 'string') {
                            toast.error(response?.data?.data)
                        }
                        else {
                            toast.error(DEFAULT_ERROR)
                        }
        
                    })).catch((err) => {
                        if (err?.response) {
                            toast.error(err?.response?.data?.data?.message || DEFAULT_ERROR)
                        };
                    })
                }

            }
            const row = JSON.parse(JSON.stringify(rows));
            row[i].disabled = !row[i].disabled
            setRows(row)
        },
        onDelete:(i,_id) => {
            if(_id){
                axios.delete(`${props.deleteUrl}/${_id}`)
                    .then((response => {
                        if (response?.data?.data?.message) {
                            toast.success(response?.data?.data?.message)
                        } else if (typeof response?.data?.data === 'string') {
                            toast.error(response?.data?.data)
                        }
                        else {
                            toast.error(DEFAULT_ERROR)
                        }
        
                    })).catch((err) => {
                        if (err?.response) {
                            toast.error(err?.response?.data?.data?.message || DEFAULT_ERROR)
                        };
                    })
            }
            const row = JSON.parse(JSON.stringify(rows));
            row.splice(i, 1)
            setRows(row)
        },
        onRowChange: (e, i) => {
            const { name, value } = e?.target;
            const row = JSON.parse(JSON.stringify(rows));
            row[i][name] = value
            setRows(row)

        }
    }

    useEffect(() => {
        axios.get(props.getUrl)
            .then((response => {
                if (response?.data) {
                    setRows(response?.data?.map(e => ({ ...e, disabled: true })))
                } else if (typeof response?.data?.data === 'string') {
                    toast.error(response?.data?.data)
                }
                else {
                    toast.error(DEFAULT_ERROR)
                }

            })).catch((err) => {
                if (err?.response) {
                    toast.error(err?.response?.data?.data?.message || DEFAULT_ERROR)
                };
            })
    }, [callApi])

    return (

        <>

            <Modal show={true} scrollable={true}>
                <Modal.Header>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-lg">
                        <div className="table-responsive">
                            <div className="table-wrapper">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            {columns?.map(e => {
                                                return (<th>{e?.name}</th>)
                                            })}
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            rows?.map((e, i) => {
                                                return (
                                                    <tr>
                                                        {columns?.map(c => {
                                                            return (<td>
                                                                <input className="form-control" type='text' name={c?.value} disabled={e?.disabled} value={e?.[c?.value]} onChange={(e) => handle.onRowChange(e, i)} />
                                                            </td>)
                                                        })}
                                                        <td>
                                                            <a className={e?.disabled ? "edit" : "add"} title={e?.disabled ? "Edit" : "Add"} data-tip={e?.disabled ? "Edit" : "Add"} onClick={() => handle.onEdit(i,e?._id,!e?.disabled)} href="# "><i className={`bi ${e?.disabled ? "bi-pencil-square" : "bi-file-earmark-plus-fill"}`} /></a>
                                                            <a className="delete" title="Delete" data-tip="Delete" onClick={() => handle.onDelete(i,e?._id)} href="# "><i className="bi bi-trash" /></a>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onClose}>
                        Close
                    </Button>
                    <Button disabled={!rows.every(e => e?.disabled)} onClick={handle.onAddNew}>
                        Add New
                    </Button>

                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Crud;