import {
    UPDATE_PATIANT_DETAIL
} from "../actions/types";

const initialState = {
    patiantInitials: "",
    patiantDob: new Date(),
    hairColor: "",
    hairCaliber: "",
    hairType: "",
    skinColor: "",
    dateOfSX: new Date(),
    surgeon: "",
    surgicalGoal: "",
    fueDeviceUsed: "",
    fueTipStyle: "",
    fueTipSize: "",
    holdingSolution: "",
    placingDeviceUsed: "",
}

export default function PatiantDetail(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case UPDATE_PATIANT_DETAIL:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
}