import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "../api/axios";
import { sendData } from "../common/common";
import { API_URL, DEFAULT_ERROR, DEFAULT_GRAFT_BUTTON, NAVIGATE_LNK } from "../common/constants";
import { useSocket } from "../hooks/useSocket";
import '../styles/graftCount.css';
const brArrayIndex = [0, 2, 5, 9, 14];

const GraftCount = () => {

    const { _id, techId } = useParams();
    const user = useSelector(e => e.auth.user);

    const navigate = useNavigate();

    const [buttons, setButtons] = useState(DEFAULT_GRAFT_BUTTON)
    const [buttons1, setButtons1] = useState([])
    const [selectedButton, setSelectedButton] = useState(-1);
    const [isLoading, setIsLoading] = useState(false);
    const [oldGraftInfo, setOldGraftInfo] = useState(false);
    const [isChange, setIsChange] = useState(1);

    const { on, off, refresh } = useSocket((res, event) => {
        console.log(res, event)
        switch (event) {
            case "getGraftCount":
                if (res?.graftCount?.hairCount_details) {
                    setButtons(res?.graftCount?.hairCount_details)
                    setButtons1(res?.graftCount?.latest10Records)
                }
                break;

            default:
                break;
        }
    })

    const handle = {
        addButton: () => {
            const x = Number(document.getElementById("x")?.value)
            const y = Number(document.getElementById("y")?.value)
            if (x > -1 && y > -1 && (x > 0 || y > 0) && !buttons.find(e => e?.x === x && e?.y === y)) {
                const b = JSON.parse(JSON.stringify(buttons))
                b.push({ x, y, count: 0 })
                document.getElementById("x").value = 0
                document.getElementById("y").value = 0
                setButtons(b)
            }
        },
        selectButton: (i) => {
            if (buttons.length > i) {
                let last10Count = JSON.parse(JSON.stringify(buttons1));
                let b = JSON.parse(JSON.stringify(buttons));
                let count = Number(JSON.parse(JSON.stringify(isChange)));
                if (count > 0 && b[i]) {
                    setIsChange(1)
                    
                   if(last10Count.find(element => (element.x === b[i].x && element.y === b[i].y))){
                        let last10RecIndex = last10Count.findIndex(element =>element["x"] === b[i].x && element["y"] === b[i].y)
                        last10Count.splice(last10RecIndex,1)
                        b[i] = { ...b[i], count: b[i]?.count ? b[i]?.count + count : count };
                        last10Count.push({...b[i], count: count});
                        if(last10Count.length>10){
                            last10Count.shift();
                        }
                        // last10Count[last10RecIndex] = {...b[i], count: count };
                        // b[i] = { ...b[i], count: b[i]?.count ? b[i]?.count + count : count };
                    }
                    else{
                        b[i] = { ...b[i], count: b[i]?.count ? b[i]?.count + count : count };
                        last10Count.push({...b[i], count: count});
                        if(last10Count.length>10){
                            last10Count.shift();
                        }
                    }
                    sendData('setHairRecord', {
                        patient_id: _id,
                        tech_id: user?._id,
                        hairCount_details: b,
                        latest10Records: last10Count,
                        tech_name: user?.userName
                    })
                    setButtons(b)
                    setButtons1(last10Count)
                    toast.clearWaitingQueue();
                    toast.success("Graft count added successfully.", { autoClose: 1000, hideProgressBar: true, position: 'bottom-center' })
                }
            }
        },
        addCount: () => {
            let b = JSON.parse(JSON.stringify(buttons))
            let count = Number(document.getElementById("count")?.value)
            if (count > 0 && b[selectedButton]) {
                document.getElementById("count").value = 0
                b[selectedButton] = { ...b[selectedButton], count: b[selectedButton]?.count ? b[selectedButton]?.count + count : count };
                sendData('setHairRecord', {
                    patient_id: _id,
                    tech_id: user?._id,
                    hairCount_details: b,
                    tech_name: user?.userName
                })
                setButtons(b)
                // setButtons1(b)
                toast.success("Graft count added successfully.", { autoClose: 1000, hideProgressBar: true, position: 'bottom-center' })
            }
        },
        deleteCountRecords: (i) => {
            const last10Count = JSON.parse(JSON.stringify(buttons1))
            const b = JSON.parse(JSON.stringify(buttons))
            const index10Record = last10Count.findIndex(element =>element["x"] === b[i].x && element["y"] === b[i].y);
            b[i] = { ...b[i], count: b[i].count - last10Count[index10Record].count };
            last10Count.splice(index10Record, 1)
            setButtons(b)
            setButtons1(last10Count)
            sendData('setHairRecord', {
                patient_id: _id,
                tech_id: user?._id,
                hairCount_details: b,
                latest10Records: last10Count,
                tech_name: user?.userName
            })
        },
        deleteButton: (i) => {
            const b = JSON.parse(JSON.stringify(buttons))
            b.splice(i, 1);
            setButtons(b)
            sendData('setHairRecord', {
                patient_id: _id,
                tech_id: user?._id,
                hairCount_details: b,
                latest10Records: b,
                tech_name: user?.userName
            })
        },
        save: () => {
            if (buttons?.length) {
                if (techId) {
                    const OD = JSON.parse(JSON.stringify(oldGraftInfo));
                    delete OD?._id
                    const requestData = {
                        ...OD,
                        patient_id: _id,
                        hairCount_details: buttons,
                        latest10Records: buttons1
                    }
                    setIsLoading(true)
                    axios.put(`${API_URL.UPDATE_GRAFT_COUNT}/${techId}`, requestData)
                        .then((response => {
                            setIsLoading(false)
                            if (response?.data?.data?.message) {
                                navigate(NAVIGATE_LNK.PATIANT_DETAIL_LIST);
                                toast.success(response?.data?.data?.message)
                            } else if (typeof response?.data?.data === 'string') {
                                toast.error(response?.data?.data)
                            }
                            else {
                                toast.error(DEFAULT_ERROR)
                            }

                        })).catch((err) => {
                            setIsLoading(false)
                            if (err?.response) {
                                toast.error(err?.response?.data?.data?.message || DEFAULT_ERROR)
                            };
                        })
                }
                else {
                    const requestData = {
                        patientId: _id,
                        countData: buttons
                    }
                    setIsLoading(true)
                    axios.post(`${API_URL.saveGraftCount}/${user?._id}`, requestData)
                        .then((response => {
                            setIsLoading(false)
                            if (response?.data?.data?.hairCount_details) {
                                navigate(NAVIGATE_LNK.PATIANT_DETAIL_LIST);
                                toast.success("graft count added successfully")
                            } else if (typeof response?.data?.data === 'string') {
                                toast.error(response?.data?.data)
                            }
                            else {
                                toast.error(DEFAULT_ERROR)
                            }

                        })).catch((err) => {
                            setIsLoading(false)
                            if (err?.response) {
                                toast.error(err?.response?.data?.data?.message || DEFAULT_ERROR)
                            };
                        })
                }
            }
        },
        increment: (tag) => {
            let count = Number(JSON.parse(JSON.stringify(isChange)));
            count += tag;
            if (count < 1) count = 1;
            setIsChange(count)
        },
        change: (e) => {
            let count = Number(e?.target?.value);
            setIsChange(count)
        }
    }

    useEffect(() => {
        refresh()
        return () => {
            // sendData("removeUsers",{tech_id: user?._id, patient_id: _id,})
            off()
        }
    }, [])

    useEffect(() => {
        if (techId) {
            setIsLoading(true)
            axios.get(`${API_URL.GRAFT_INFO}/${techId}/${_id}`,)
                .then((response => {
                    setIsLoading(false)
                    if (response?.data?.data) {
                        setOldGraftInfo(response?.data?.data)
                        setButtons(response?.data?.data?.hairCount_details)
                        setButtons1(response?.data?.data?.latest10Records)
                    } else if (typeof response?.data?.data === 'string') {
                        toast.error(response?.data?.data)
                    }
                    else {
                        toast.error(DEFAULT_ERROR)
                    }
                })).catch((err) => {
                    setIsLoading(false)
                    if (err?.response) {
                        toast.error(err?.response?.data?.data?.message || DEFAULT_ERROR)
                    };
                })
        }
        else sendData("getGraftCount", { tech_id: user?._id, patient_id: _id })
    }, [techId])

    return (

        <div className="container-fluid">
            {isLoading ? <div className="center-screen"> <ThreeDots color="#0000cd" /> </div> : <>
                <div className="divide-up">
                    <div className="divide-right">
                        <div className="col">
                            <div className="row">
                                <input type="number" min="0" placeholder="X." id="x" className="circle-input" />
                                <input type="number" min="0" placeholder="Y." id="y" className="circle-input" />
                                <button className="btn btn-primary btn-lg mx-2 margin-top-20" onClick={handle.addButton}>
                                    <span>Add Button</span>
                                </button>

                            </div>
                            <div className="row">
                                {/* <input type="number" min="0" placeholder="Count" id="count" className="circle-input" /> */}
                                <div className="number-input">
                                    <input type="number" id="increment" value={isChange} onChange={handle.change} className="circle-input" min="1" />
                                    <div className="spinners">
                                        <button className="spinner increment" onClick={() => handle.increment(1)}>&#9650;</button>
                                        <button className="spinner decrement" onClick={() => handle.increment(-1)}>&#9660;</button>
                                    </div>
                                </div>
                                {/* <button className="btn d-inline-flex btn-lg btn-primary mx-2" onClick={handle.addCount}>
                            <span>Add Count</span>
                        </button> */}
                                <button className="btn btn-primary btn-lg mx-2 margin-top-20" onClick={handle.save}>
                                    <span>Save</span>
                                </button>
                            </div>
                        </div>
                        <div className="divide-right-down">
                            <table>
                                <tr>
                                    <th>No</th>
                                    <th>X/Y</th>
                                    <th>Count</th>
                                    <th>Action</th>
                                </tr>
                                {[...buttons1].reverse().map((e, i) => {
                                    return (<>
                                        <tr key={i} data-ng-repeat="customer in people | filter: table">
                                            <td>{i + 1}</td>
                                            <td>{`${e.x}/${e.y}`}</td>
                                            <td>{e?.count}</td>
                                            <td>
                                                <i className="bi-trash cursor-pointer" onClick={() => { handle.deleteCountRecords(buttons.findIndex(element =>element["x"] === e.x && element["y"] === e.y)) }}></i>
                                                {/* <i className="bi-trash cursor-pointer" onClick={() => { handle.deleteCountRecords(buttons1[i]) }}></i> */}
                                            </td>
                                        </tr>
                                    </>)
                                })}
                            </table>
                        </div> 
                    </div>

                    <div className="divide-left">
                        {buttons.map((e, i) => {
                        return (
                            <>
                                <button key={i} className={`circle-button-wm ${i === selectedButton ? 'circle-button-selected' : ''}`} onClick={() => handle.selectButton(i)}>
                                    <span>{`${e.x}/${e.y}`}</span>
                                </button>
                                {brArrayIndex?.includes(i) && <br style={{ clear: "both" }} />}
                            </>
                        )
                    })}
                    </div>

                </div>
                <div className="divide-down">
                    <table>
                        <tr>
                            <th>No</th>
                            <th>X/Y</th>
                            <th>Count</th>
                            <th>Action</th>
                        </tr>
                        {buttons.map((e, i) => {
                            return (<>
                                <tr key={i} data-ng-repeat="customer in people | filter: table">
                                    <td>{i + 1}</td>
                                    <td>{`${e.x}/${e.y}`}</td>
                                    <td>{e?.count}</td>
                                    <td>
                                        <i className="bi-trash cursor-pointer" onClick={() => { handle.deleteButton(i) }}></i>
                                    </td>
                                </tr>
                            </>)
                        })}
                    </table>
                </div>
            </>}
        </div>
    );
};

export default GraftCount;