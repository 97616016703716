import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "../api/axios";
import { API_URL, DEFAULT_ERROR, DEFAULT_PAGINATION, NAVIGATE_LNK } from "../common/constants";
import Pagination from "../component/Pagination";
import { Alert } from "../component/Popup";

const TechByData = () => {

    const navigate = useNavigate()
    const {_id} = useParams()

    const [isLoading, setIsLoading] = useState(false);
    const [list, setList] = useState([])

    const handle = {
        edit:(techId)=> {
            navigate(`${NAVIGATE_LNK.GRAFT_COUNT}/${_id}/${techId}`)
        }
    }
    
    useEffect(() => {
        setIsLoading(true)
        axios.get(`${API_URL.TECH_BY_DATA}/${_id}`, )
        .then((response => {
            setIsLoading(false)
            if (response?.data?.data) {
                setList(response?.data?.data)
            } else if (typeof response?.data?.data === 'string') {
                toast.error(response?.data?.data)
            }
            else {
                toast.error(DEFAULT_ERROR)
            }
        })).catch((err) => {
            setIsLoading(false)
            if (err?.response) {
                toast.error(err?.response?.data?.data?.message || DEFAULT_ERROR)
            };
        })
    }, [_id])

    return (
        <>
            <div className="container-fluid">

                <div className="line">
                    <span>Technician List</span>
                </div>
                {isLoading ? <div className="center-screen"> <ThreeDots color="#0000cd" /> </div> :
                    <> <table>
                        <tr>
                            <th>No</th>
                            <th>Name</th>
                            <th>Action</th>
                        </tr>
                        {list.map((e, i) => {
                            return (<>
                                <tr id={e?._id} data-ng-repeat="customer in people | filter: table">
                                    <td>{i + 1}</td>
                                    <td>{e?.tech_name}</td>
                                    <td>
                                        <i className="bi bi-pencil-square cursor-pointer" onClick={() => { handle.edit(e?.tech_id) }}></i>
                                    </td>
                                </tr>
                            </>)
                        })}
                    </table>
                    </>
                }
            </div>

        </>
    );
};

export default TechByData;