import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "../api/axios";
import { API_URL, DEFAULT_ERROR, DEFAULT_PAGINATION, NAVIGATE_LNK } from "../common/constants";
import Pagination from "../component/Pagination";
import { Alert } from "../component/Popup";

const TechnicianList = () => {

    const navigate = useNavigate()

    const user = useSelector(e => e.auth.user);

    const [pagination, setPaginaion] = useState(DEFAULT_PAGINATION);
    const [isLoading, setIsLoading] = useState(false);
    const [list, setList] = useState([])
    const [totalRecords, setTotalRecords] = useState(0)

    const handle = {
        addTechnician: () => {
            navigate(NAVIGATE_LNK.ADD_TECHNICIAN)
        },
        deleteTechnician: (email) => {
            Alert.show({
                title: "Are you sure?",
                message: "Do you want to delete this technician?",
                onSubmit: () => {
                    setIsLoading(true)
                    axios.delete(`${API_URL.DELETE_USER}`, { data: { email } })
                        .then((response => {
                            setIsLoading(false)
                            if (response?.data?.data?.message) {
                                toast.success(response?.data?.data?.message)
                                fetchList();
                            } else if (typeof response?.data?.data === 'string') {
                                toast.error(response?.data?.data)
                            }
                            else {
                                toast.error(DEFAULT_ERROR)
                            }

                        })).catch((err) => {
                            setIsLoading(false)
                            if (err?.response) {
                                toast.error(err?.response?.data?.data?.message || DEFAULT_ERROR)
                            };
                        })
                }
            });
        }
    }

    const fetchList = () => {
        const requestData = {
            page: pagination.currentPage,
            pageSize: pagination.limit
        }
        axios.get(`${API_URL.TECHNICIAN_LISTL}/${user._id}`, { params: requestData })
            .then((response => {
                setIsLoading(false)
                if (response?.data?.data?.row) {
                    setList(response?.data?.data?.row)
                    setTotalRecords(response?.data?.data?.pagination?.totalRecords || 0)
                } else if (typeof response?.data?.data === 'string') {
                    toast.error(response?.data?.data)
                }
                else {
                    toast.error(DEFAULT_ERROR)
                }

            })).catch((err) => {
                setIsLoading(false)
                if (err?.response) {
                    toast.error(err?.response?.data?.data?.message || DEFAULT_ERROR)
                };
            })
    }
    
    useEffect(() => {
        setIsLoading(true)
        fetchList()
    }, [pagination])

    return (
        <>
            <div className="container-fluid">

                <div className="line">
                    <span>Technician List</span>
                    <div className="float-right">
                        <a className="btn d-inline-flex btn-sm btn-primary mx-1" onClick={handle.addTechnician}>
                            <span className=" pe-2">
                                <i className="bi bi-plus"></i>
                            </span>
                            <span>Add Technician</span>
                        </a>
                    </div>
                </div>
                {isLoading ? <div className="center-screen"> <ThreeDots color="#0000cd" /> </div> :
                    <> <table>
                        <tr>
                            <th>No</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Action</th>
                        </tr>
                        {list.map((e, i) => {
                            return (<>
                                <tr id={e?._id} data-ng-repeat="customer in people | filter: table">
                                    <td>{i + 1}</td>
                                    <td>{e?.userName}</td>
                                    <td>{e?.email}</td>
                                    <td>
                                        <i className="bi-trash cursor-pointer" onClick={() => { handle.deleteTechnician(e?.email) }}></i>
                                    </td>
                                </tr>
                            </>)
                        })}
                    </table>
                        <Pagination
                            className="pagination-bar"
                            currentPage={pagination.currentPage}
                            totalRecords={totalRecords}
                            limit={pagination.limit}
                            onPageChange={page => setPaginaion({ ...pagination, currentPage: page })}
                        />
                    </>
                }
            </div>

        </>
    );
};

export default TechnicianList;