export const API_URL = {
    LOGIN: '/login',
    SIGNUP: '/signup',
    ADD_PATIENT_DETAILS: '/addpatient',
    PATIANT_DETAIL_LIST: '/showallpatient',
    VIEW_PATIANT_DETAILS: '/patientdetails',
    DOCTOR_LIST: '/showalldoctor',
    TECHNICIAN_LISTL: '/showalltechnician',
    DELETE_USER: '/deleteusers',
    DASHBOARD: '/doctorcount',
    saveGraftCount: '/haircounts',
    SUMURY_REPORT:'/surgeryreport',
    GRAFT_COUNT_LIST:'/haircountbytechlist',
    DELETE_GRAFT_COUNT:'/deletehaircounts',
    SURGICAL_DASHBOARD:'/surgerydashboard',
    SX_TIME: '/sxstartstoptime',
    EXTRACTION_TIME: '/extractionstartstop',
    PLACING_TIME: '/placingstartstop',
    UPDATE_PATIENT_DETAILS: '/updatepatientdetails',
    TECH_BY_DATA: '/gettechlist',
    GRAFT_INFO: '/gethaircountbytech',
    UPDATE_GRAFT_COUNT: '/updatehaircounts',
    GENRATE_PDF: '/generatepdf',
    SEARCH_PATIENT: '/searchPatientByName',
    RUNNING_SURGERY_LIST:'/runningSurgeryList'
}

export const USER_TYPE = {
    1: "SUPER ADMIN",
    2: "DOCTOR",
    3: "TECHNICIAN",
}

export const DEFAULT_PAGINATION = {
    currentPage: 1,
    limit: 25
}

export const USER_TYPE_CONSTANT = {
    ADMIN: 1,
    DOCTOR: 2,
    TECHNICIAN: 3
}

export const DEFAULT_ERROR = "Something went wrong please try again!"

export const NAVIGATE_LNK = {
    ADD_TECHNICIAN: "/add-technician",
    PATIANT_DETAIL: "/patiant-detail",
    DASHBOARD: "/dashboard",
    SETTING: "/setting",
    HOME: "/home",
    DOCTOR_LIST: "/docter-list",
    TECHNICIAN_LIST: "/technician-list",
    TECH_BY_DATA: "/technician-by-data",
    ADD_DOCTOR: "/add-doctor",
    PATIANT_DETAIL_LIST: "/patiant-detail-list",
    VIEW_PATIANT_DETAILS: "/view-patiant-details",
    GRAFT_COUNT: "/graft-count",
    SUMURY_REPORT:"/sumury-report",
    GRAFT_COUNT_LIST:"/graft-count-list",
    SURGICAL_DASHBOARD:"/surgical-dashboard",
}

export const DEFAULT_GRAFT_BUTTON = [
    { x: 1, y: 1, count: 0 },
    { x: 2, y: 2, count: 0 },
    { x: 1, y: 2, count: 0 },
    { x: 3, y: 3, count: 0 },
    { x: 2, y: 3, count: 0 },
    { x: 1, y: 3, count: 0 },
    { x: 4, y: 4, count: 0 },
    { x: 3, y: 4, count: 0 },
    { x: 2, y: 4, count: 0 },
    { x: 1, y: 4, count: 0 },
]

export const toHHMMSS = (secs) => {
    let sec_num = parseInt(secs, 10)
    let hours   = Math.floor(sec_num / 3600)
    let minutes = Math.floor(sec_num / 60) % 60
    let seconds = sec_num % 60

    return [hours,minutes,seconds]
        .map(v => v < 10 ? "0" + v : v)
        .filter((v,i) => v !== "00" || i > 0)
        .join(":")
}

export const SERVER_BASE_URL = 'http://13.57.150.214:3003/'; //13.235.223.204  old ip
// export const SERVER_BASE_URL = 'http://localhost:3003/';