import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import '../styles/dashboard.css'

import { API_URL, DEFAULT_ERROR, USER_TYPE_CONSTANT } from "../common/constants";
import { ThreeDots } from "react-loader-spinner";
import axios from "../api/axios";
import { toast } from "react-toastify";
import Crud from "../component/Crud";

const Setting = () => {

    const user = useSelector(e => e.auth.user);
    const userType = user?.userType

    const [counter, setCounter] = useState({
        doctor: 0, technician: 0, hairColorCount: 0, hairCaliberCount: 0, hairTypeCount: 0, skinColorCount: 0, deviceUsedCount: 0, deviceSurgeonCount: 0, tipStyleCount: 0, tipSizeCount: 0, holdingSolutionCount: 0, surgeryGoalsCount: 0, placingDeviceUsedCount: 0
    })

    const [isLoading, setIsLoading] = useState(true);
    const [openModel, setOpenModel] = useState({ open: false, columns: [], title: "", getUrl: '', updateUrl: '', addUrl: '', deleteUrl: '', });

    useEffect(() => {
        Promise.all([
            axios.get('gethairtype'),
            axios.get('gethaircolor'),
            axios.get('gethaircaliber'),
            axios.get('getskincolor'),
            axios.get('getdeviceused'),
            axios.get('getsurgeon'),
            axios.get('gettipstyle'),
            axios.get('gettipsize'),
            axios.get('getholdingsolution'),
            axios.get('getsurgerygoals'),
            axios.get('getplacersused'),
            axios.get(`${API_URL.DASHBOARD}/${user._id}`)
        ]).then(response => {
            setIsLoading(false);
            const hairTypeCount = response[0]?.data?.length || 0;
            const hairColorCount = response[1]?.data?.length || 0;
            const hairCaliberCount = response[2]?.data?.length || 0;
            const skinColorCount = response[3]?.data?.length || 0;
            const deviceUsedCount = response[4]?.data?.length || 0;
            const deviceSurgeonCount = response[5]?.data?.length || 0;
            const tipStyleCount = response[6]?.data?.length || 0;
            const tipSizeCount = response[7]?.data?.length || 0;
            const holdingSolutionCount = response[8]?.data?.length || 0;
            const surgeryGoalsCount = response[9]?.data?.length || 0;
            const placingDeviceUsedCount = response[10]?.data?.length || 0;
            const doctor = response[11]?.data?.data?.doctorCount || 0;
            const technician = response[11]?.data?.data?.techniciansCount || 0;

            setCounter({
                hairTypeCount,
                hairColorCount,
                hairCaliberCount,
                skinColorCount,
                deviceUsedCount,
                deviceSurgeonCount,
                tipStyleCount,
                tipSizeCount,
                holdingSolutionCount,
                surgeryGoalsCount,
                placingDeviceUsedCount,
                doctor,
                technician
            })
        }).catch(err => {
            setIsLoading(false);
            if (err?.response) {
                toast.error(err?.response?.data?.data?.message || DEFAULT_ERROR)
            };
        })

    }, [])


    const handle = {
        openSetting: (type) => {
            switch (type) {
                case 'hairType':
                    setOpenModel({
                        ...openModel,
                        open: true,
                        columns: [{ name: 'Hair Type', value: 'name' }],
                        title: "Hair Type",
                        getUrl: 'gethairtype',
                        updateUrl: 'updatehairtype',
                        addUrl: 'sethairtype',
                        deleteUrl: 'deletehairtype',
                    })
                    break;
                case 'hairColor':
                    setOpenModel({
                        ...openModel,
                        open: true,
                        columns: [{ name: 'Hair Color', value: 'name' }],
                        title: "Hair Color",
                        getUrl: 'gethaircolor',
                        updateUrl: 'updatehaircolor',
                        addUrl: 'sethaircolor',
                        deleteUrl: 'deletehaircolor',
                    })
                    break;
                case 'hairCaliber':
                    setOpenModel({
                        ...openModel,
                        open: true,
                        columns: [{ name: 'Hair Caliber', value: 'name' }],
                        title: "Hair Caliber",
                        getUrl: 'gethaircaliber',
                        updateUrl: 'updatehaircaliber',
                        addUrl: 'sethaircaliber',
                        deleteUrl: 'deletehaircaliber',
                    })
                    break;
                case 'skinColor':
                    setOpenModel({
                        ...openModel,
                        open: true,
                        columns: [{ name: 'Skin Color', value: 'name' }],
                        title: "Skin Color",
                        getUrl: 'getskincolor',
                        updateUrl: 'updateskincolor',
                        addUrl: 'setskincolor',
                        deleteUrl: 'deleteskincolor',
                    })
                    break;
                case 'deviceUsed':
                    setOpenModel({
                        ...openModel,
                        open: true,
                        columns: [{ name: 'Device Used', value: 'name' }],
                        title: "Device Used",
                        getUrl: 'getdeviceused',
                        updateUrl: 'updatedeviceused',
                        addUrl: 'setdeviceused',
                        deleteUrl: 'deletedeviceused',
                    })
                    break;
                case 'placingDeviceUsed':
                    setOpenModel({
                        ...openModel,
                        open: true,
                        columns: [{ name: 'Placing Device Used', value: 'name' }],
                        title: "Placing Device Used",
                        getUrl: 'getplacersused',
                        updateUrl: 'updateplacersused',
                        addUrl: 'setplacersused',
                        deleteUrl: 'deleteplacersused',
                    })
                    break;
                case 'deviceSurgeon':
                    setOpenModel({
                        ...openModel,
                        open: true,
                        columns: [{ name: 'Surgeon', value: 'name' }],
                        title: "Surgeon",
                        getUrl: 'getsurgeon',
                        updateUrl: 'updatesurgeon',
                        addUrl: 'setsurgeon',
                        deleteUrl: 'deletesurgeon',
                    })
                    break;
                case 'tipStyle':
                    setOpenModel({
                        ...openModel,
                        open: true,
                        columns: [{ name: 'Tip Style', value: 'name' }],
                        title: "Tip Style",
                        getUrl: 'gettipstyle',
                        updateUrl: 'updatetipstyle',
                        addUrl: 'settipstyle',
                        deleteUrl: 'deletetipstyle',
                    })
                    break;
                case 'tipSize':
                    setOpenModel({
                        ...openModel,
                        open: true,
                        columns: [{ name: 'Tip Size', value: 'name' }],
                        title: "Tip Size",
                        getUrl: 'gettipsize',
                        updateUrl: 'updatetipsize',
                        addUrl: 'settipsize',
                        deleteUrl: 'deletetipsize',
                    })
                    break;
                case 'holdingSolution':
                    setOpenModel({
                        ...openModel,
                        open: true,
                        columns: [{ name: 'Holding Solution', value: 'name' }],
                        title: "Holding Solution",
                        getUrl: 'getholdingsolution',
                        updateUrl: 'updateholdingsolution',
                        addUrl: 'setholdingsolution',
                        deleteUrl: 'deleteholdingsolution',
                    })
                    break;
                case 'surgeryGoals':
                    setOpenModel({
                        ...openModel,
                        open: true,
                        columns: [{ name: 'Surgery Goals', value: 'name' }],
                        title: "Surgery Goals",
                        getUrl: 'getsurgerygoals',
                        updateUrl: 'updatesurgerygoals',
                        addUrl: 'setsurgerygoals',
                        deleteUrl: 'deletesurgerygoals',
                    })
                    break;
                default:
                    break;
            }
        }
    }

    return (
        <>
            {/* <!-- Main content --> */}
            {isLoading && <div className="center-screen"> <ThreeDots color="#0000cd" /> </div>}
            {!isLoading && <div className="h-screen flex-grow-1 overflow-y-lg-auto">
                <main className="py-6 bg-surface-secondary">
                    <div className="container-fluid">
                        {/* <!-- Card stats --> */}
                        <div className="row g-6 mb-6">
                            {userType === USER_TYPE_CONSTANT.ADMIN && <div className="col-xl-3 col-sm-6 col-12">
                                <div className="card shadow border-0">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col">
                                                <span className="h6 font-semibold text-muted text-sm d-block mb-2">Total Doctors</span>
                                                <span className="h3 font-bold mb-0">{counter?.doctor || 0}</span>
                                            </div>
                                            <div className="col-auto">
                                                <div className="icon icon-shape bg-tertiary text-white text-lg rounded-circle">
                                                    <i className="bi-people"></i>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {userType !== USER_TYPE_CONSTANT.TECHNICIAN && <div className="col-xl-3 col-sm-6 col-12">
                                <div className="card shadow border-0">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col">
                                                <span className="h6 font-semibold text-muted text-sm d-block mb-2">Total Technicians</span>
                                                <span className="h3 font-bold mb-0">{counter?.technician || 0}</span>
                                            </div>
                                            <div className="col-auto">
                                                <div className="icon icon-shape bg-primary text-white text-lg rounded-circle">
                                                    <i className="bi-people"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {true && <div className="col-xl-3 col-sm-6 col-12">
                                <div className="card shadow border-0">
                                    <div className="card-body">
                                        <div className="row cursor-pointer" onClick={() => handle.openSetting('hairType')}>
                                            <div className="col">
                                                <span className="h6 font-semibold text-muted text-sm d-block mb-2">Hair Type</span>
                                                <span className="h3 font-bold mb-0">{counter?.hairTypeCount || 0}</span>
                                            </div>
                                            <div className="col-auto">
                                                <div className="icon icon-shape bg-primary text-white text-lg rounded-circle">
                                                    <i className="bi-people"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {true && <div className="col-xl-3 col-sm-6 col-12">
                                <div className="card shadow border-0">
                                    <div className="card-body">
                                        <div className="row cursor-pointer" onClick={() => handle.openSetting('hairColor')}>
                                            <div className="col">
                                                <span className="h6 font-semibold text-muted text-sm d-block mb-2">Hair Color</span>
                                                <span className="h3 font-bold mb-0">{counter?.hairColorCount || 0}</span>
                                            </div>
                                            <div className="col-auto">
                                                <div className="icon icon-shape bg-primary text-white text-lg rounded-circle">
                                                    <i className="bi-people"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {true && <div className="col-xl-3 col-sm-6 col-12">
                                <div className="card shadow border-0">
                                    <div className="card-body">
                                        <div className="row cursor-pointer" onClick={() => handle.openSetting('hairCaliber')}>
                                            <div className="col">
                                                <span className="h6 font-semibold text-muted text-sm d-block mb-2">Hair Caliber</span>
                                                <span className="h3 font-bold mb-0">{counter?.hairCaliberCount || 0}</span>
                                            </div>
                                            <div className="col-auto">
                                                <div className="icon icon-shape bg-primary text-white text-lg rounded-circle">
                                                    <i className="bi-people"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {true && <div className="col-xl-3 col-sm-6 col-12">
                                <div className="card shadow border-0">
                                    <div className="card-body">
                                        <div className="row cursor-pointer" onClick={() => handle.openSetting('skinColor')}>
                                            <div className="col">
                                                <span className="h6 font-semibold text-muted text-sm d-block mb-2">Skin Color</span>
                                                <span className="h3 font-bold mb-0">{counter?.skinColorCount || 0}</span>
                                            </div>
                                            <div className="col-auto">
                                                <div className="icon icon-shape bg-primary text-white text-lg rounded-circle">
                                                    <i className="bi-people"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {true && <div className="col-xl-3 col-sm-6 col-12">
                                <div className="card shadow border-0">
                                    <div className="card-body">
                                        <div className="row cursor-pointer" onClick={() => handle.openSetting('deviceUsed')}>
                                            <div className="col">
                                                <span className="h6 font-semibold text-muted text-sm d-block mb-2">Device Used</span>
                                                <span className="h3 font-bold mb-0">{counter?.deviceUsedCount || 0}</span>
                                            </div>
                                            <div className="col-auto">
                                                <div className="icon icon-shape bg-primary text-white text-lg rounded-circle">
                                                    <i className="bi-people"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {true && <div className="col-xl-3 col-sm-6 col-12">
                                <div className="card shadow border-0">
                                    <div className="card-body">
                                        <div className="row cursor-pointer" onClick={() => handle.openSetting('placingDeviceUsed')}>
                                            <div className="col">
                                                <span className="h6 font-semibold text-muted text-sm d-block mb-2">Placing Device Used</span>
                                                <span className="h3 font-bold mb-0">{counter?.placingDeviceUsedCount || 0}</span>
                                            </div>
                                            <div className="col-auto">
                                                <div className="icon icon-shape bg-primary text-white text-lg rounded-circle">
                                                    <i className="bi-people"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {true && <div className="col-xl-3 col-sm-6 col-12">
                                <div className="card shadow border-0">
                                    <div className="card-body">
                                        <div className="row cursor-pointer" onClick={() => handle.openSetting('deviceSurgeon')}>
                                            <div className="col">
                                                <span className="h6 font-semibold text-muted text-sm d-block mb-2">Surgeon</span>
                                                <span className="h3 font-bold mb-0">{counter?.deviceSurgeonCount || 0}</span>
                                            </div>
                                            <div className="col-auto">
                                                <div className="icon icon-shape bg-primary text-white text-lg rounded-circle">
                                                    <i className="bi-people"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {true && <div className="col-xl-3 col-sm-6 col-12">
                                <div className="card shadow border-0">
                                    <div className="card-body">
                                        <div className="row cursor-pointer" onClick={() => handle.openSetting('tipStyle')}>
                                            <div className="col">
                                                <span className="h6 font-semibold text-muted text-sm d-block mb-2">Tip Style</span>
                                                <span className="h3 font-bold mb-0">{counter?.tipStyleCount || 0}</span>
                                            </div>
                                            <div className="col-auto">
                                                <div className="icon icon-shape bg-primary text-white text-lg rounded-circle">
                                                    <i className="bi-people"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {true && <div className="col-xl-3 col-sm-6 col-12">
                                <div className="card shadow border-0">
                                    <div className="card-body">
                                        <div className="row cursor-pointer" onClick={() => handle.openSetting('tipSize')}>
                                            <div className="col">
                                                <span className="h6 font-semibold text-muted text-sm d-block mb-2">Tip Size</span>
                                                <span className="h3 font-bold mb-0">{counter?.tipSizeCount || 0}</span>
                                            </div>
                                            <div className="col-auto">
                                                <div className="icon icon-shape bg-primary text-white text-lg rounded-circle">
                                                    <i className="bi-people"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {true && <div className="col-xl-3 col-sm-6 col-12">
                                <div className="card shadow border-0">
                                    <div className="card-body">
                                        <div className="row cursor-pointer" onClick={() => handle.openSetting('holdingSolution')}>
                                            <div className="col">
                                                <span className="h6 font-semibold text-muted text-sm d-block mb-2">Holding Solution</span>
                                                <span className="h3 font-bold mb-0">{counter?.holdingSolutionCount || 0}</span>
                                            </div>
                                            <div className="col-auto">
                                                <div className="icon icon-shape bg-primary text-white text-lg rounded-circle">
                                                    <i className="bi-people"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {true && <div className="col-xl-3 col-sm-6 col-12">
                                <div className="card shadow border-0">
                                    <div className="card-body">
                                        <div className="row cursor-pointer" onClick={() => handle.openSetting('surgeryGoals')}>
                                            <div className="col">
                                                <span className="h6 font-semibold text-muted text-sm d-block mb-2">Surgery Goals</span>
                                                <span className="h3 font-bold mb-0">{counter?.surgeryGoalsCount || 0}</span>
                                            </div>
                                            <div className="col-auto">
                                                <div className="icon icon-shape bg-primary text-white text-lg rounded-circle">
                                                    <i className="bi-people"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </main>
            </div>}
            {!isLoading && openModel?.open && <Crud
                columns={openModel.columns}
                title={openModel.title}
                getUrl={openModel.getUrl}
                updateUrl={openModel.updateUrl}
                addUrl={openModel.addUrl}
                deleteUrl={openModel.deleteUrl}
                onClose={() => setOpenModel({ open: false, columns: [], title: "" })}
            />}
        </>
    );
};

export default Setting;