import { confirmAlert } from 'react-confirm-alert';

export const Alert = {
    show: ({ title, message, onSubmit, onClose }) => {
        confirmAlert({
            title,
            message,
            buttons: [
                {
                    label: 'Yes',
                    onClick: onSubmit
                },
                {
                    label: 'No',
                    onClick: onClose
                }
            ],
            closeOnEscape: true,
        });
    }
}



