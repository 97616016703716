import React, { useState } from "react";
import { useSelector } from "react-redux";
import { API_URL, DEFAULT_ERROR, NAVIGATE_LNK, USER_TYPE_CONSTANT } from "../common/constants";
import '../styles/addUser.css'
import axios from "../api/axios";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { ThreeDots } from "react-loader-spinner";

const AddTechnician = () => {
    const navigate = useNavigate()


    const user = useSelector((e) => e.auth.user);

    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [inputText, setInputText] = useState({
        name: "",
        email: "",
        password: ""
    })

    const handle = {
        submit: (e) => {
            e.preventDefault();
            if (user?.userType === USER_TYPE_CONSTANT.TECHNICIAN) return
            setError("");
            if (inputText.name?.length < 3) {
                setError("Name must be greater then or equal to three character!");
            } else if (inputText.password?.length < 6) {
                setError("Password must be greater then or equal to six character!");
            }
            else if (inputText.email?.length < 3) {
                setError("Please enter a valid email!");
            } else {
                setIsLoading(true)
                const requestData = {
                    userName: inputText?.name,
                    password: inputText?.password,
                    email: inputText?.email,
                    userType: USER_TYPE_CONSTANT.TECHNICIAN,
                    parentId: user?._id
                }
                axios.post(API_URL.SIGNUP, requestData)
                    .then((response => {
                        setInputText({
                            name: "",
                            email: "",
                            password: ""
                        });
                        setIsLoading(false)
                        if (response?.data?.data?._id) {
                            navigate(NAVIGATE_LNK.TECHNICIAN_LIST);
                            toast.success("Technician added successfully")
                        } else if (typeof response?.data?.data === 'string') {
                            setError(response?.data?.data)
                        }
                        else {
                            setError(DEFAULT_ERROR)
                        }

                    })).catch((err) => {
                        setInputText({
                            name: "",
                            email: "",
                            password: ""
                        });
                        setIsLoading(false)
                        if (err?.response) {
                            setError(err?.response?.data?.data?.message || DEFAULT_ERROR)
                        };
                    })
            }

        },
        inputEvent: (event) => {
            const { name, value } = event.target;
            setError("")
            setInputText((oldValues) => {
                return {
                    ...oldValues,
                    [name]: value
                }

            });
        }
    }

    return (
        <>
            <div className="container">
                <h1> Add Technician</h1>
                {isLoading && <div className="center-screen"> <ThreeDots color="#0000cd" /> </div>}
                {!isLoading && <form id="contact" method="post" action="">
                    <small className="error">{error}</small>

                    <div className="line">
                        <label for="name">Name: </label>
                        <input type="text" name="name" id="name" onChange={handle.inputEvent} value={inputText.name} />
                    </div>


                    <div className="line">
                        <label for="email">E-Mail: </label>
                        <input type="email" name="email" id="email" onChange={handle.inputEvent} value={inputText.email} />
                    </div>


                    <div className="line">
                        <label for="password">Password: </label>
                        <input type="password" name="password" id="password" onChange={handle.inputEvent} value={inputText.password} />
                    </div>

                    <div className="line">
                        <a className="btn d-inline-flex btn-sm btn-primary mx-1" onClick={handle.submit}>
                            <span className=" pe-2">
                                <i className="bi bi-plus"></i>
                            </span>
                            <span>Add Technician </span>
                        </a>
                    </div>


                </form>}
            </div>
        </>
    );
};

export default AddTechnician;