import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { toHHMMSS } from "../common/constants";

const Timer = ({ startTime = 0, endTime = 0 }) => {

    const [time, setTime] = useState(moment(endTime).diff(startTime, 'second'));

    const inertval = setInterval(() => {
        setTime(p => p + 1)
    }, 1000)

    useEffect(() => {
        return () => clearInterval(inertval)
    })

    return (
        <div>
            {toHHMMSS(time)}
        </div>
    );
};

export default Timer;