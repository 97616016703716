import React from 'react';
import { IgrRadialGauge, IgrRadialGaugeModule } from 'igniteui-react-gauges';

IgrRadialGaugeModule.register();

export default class GaugeChart extends React.Component {

    constructor(props) {
        super(props);

        this.state = { componentVisible: true }
    }

    render() {
        return (
            <IgrRadialGauge
            labelExtent={0.68}
                font="9px Verdana"
                scaleStartAngle={135}
                scaleEndAngle={45}
                scaleBrush="DodgerBlue"
                scaleSweepDirection="Clockwise"
                scaleOversweep={1}
                scaleOversweepShape="Fitted"
                scaleStartExtent={0.45}
                scaleEndExtent={0.575}
                height="380px"
                width="272px"
                minimumValue={0} value={this.props.chartValue}
                maximumValue={this.props.max} interval={this.props.max * 10 / 100} />
        );
    }
}
