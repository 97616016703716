import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "../api/axios";
import { API_URL, DEFAULT_ERROR, DEFAULT_PAGINATION, NAVIGATE_LNK, USER_TYPE_CONSTANT } from "../common/constants";
import PageNotFound from "../component/PageNotFound";
import Pagination from "../component/Pagination";

const PatiantDetailList = () => {

    const navigate = useNavigate()
    const user = useSelector(e => e.auth.user);

    const [pagination, setPaginaion] = useState(DEFAULT_PAGINATION);
    const [isLoading, setIsLoading] = useState(false);
    const [list, setList] = useState([])
    const [totalRecords, setTotalRecords] = useState(0)
    const [search, setSearch] = useState("")


    const handle = {
        addPatientDetail: () => {
            navigate(NAVIGATE_LNK.PATIANT_DETAIL)
        },
        viewPatientDetail: (_id) => {
            navigate(`${NAVIGATE_LNK.VIEW_PATIANT_DETAILS}/${_id}`)
        },
        start: (_id, activation_status) => {
            if (user?.userType === USER_TYPE_CONSTANT.TECHNICIAN && activation_status !== 2 && activation_status !== 0) navigate(`${NAVIGATE_LNK.GRAFT_COUNT}/${_id}`)
            else toast.error("Surgery is not started or it is completed");
        },
        summaryReport: (_id) => {
            if (user?.userType !== USER_TYPE_CONSTANT.TECHNICIAN) navigate(`${NAVIGATE_LNK.SUMURY_REPORT}/${_id}`)
        },
        surgicalReport: (_id) => {
            if (user?.userType !== USER_TYPE_CONSTANT.TECHNICIAN) navigate(`${NAVIGATE_LNK.SURGICAL_DASHBOARD}/${_id}`)
        },
        search: (e) => {
            setSearch(e?.target?.value)
        }
    }

    useEffect(() => {
        setIsLoading(true)
        const requestData = {
            page: pagination.currentPage,
            pageSize: pagination.limit,
            name: search
        }
        axios.get(`${API_URL.PATIANT_DETAIL_LIST}/${user._id}`, { params: requestData })
            .then((response => {
                setIsLoading(false)
                if (response?.data?.data?.row) {
                    setList(response?.data?.data?.row)
                    setTotalRecords(response?.data?.data?.pagination?.totalRecords || 0)
                } else if (typeof response?.data?.data === 'string') {
                    toast.error(response?.data?.data)
                }
                else {
                    toast.error(DEFAULT_ERROR)
                }

            })).catch((err) => {
                setIsLoading(false)
                if (err?.response) {
                    toast.error(err?.response?.data?.data?.message || DEFAULT_ERROR)
                };
            })
    }, [pagination, search])

    return (
        <>
            <div className="container-fluid">
                <div className="line">
                    <span>Previous Surgeries</span>
                    {
                        user?.userType !== USER_TYPE_CONSTANT.TECHNICIAN && <>
                            <div className="float-right">
                                <a className="btn d-inline-flex btn-sm btn-primary mx-1" onClick={handle.addPatientDetail} href="# ">
                                    <span className=" pe-2">
                                        <i className="bi bi-plus"></i>
                                    </span>
                                    <span>Add Surgery Tracker</span>
                                </a>
                            </div>
                            <div className="float-right">
                                <div className="form-outline">
                                    <input onChange={handle.search} name="search" placeholder="search" type="search" className="form-control" value={search}/>
                                </div>
                            </div>
                        </>
                    }
                </div>
                {isLoading ? <div className="center-screen"> <ThreeDots color="#0000cd" /> </div> :
                    list?.length ? <> <table>
                        <tr>
                            <th>No</th>
                            <th>Name</th>
                            {user?.userType === USER_TYPE_CONSTANT.ADMIN && <th>Doctor</th>}
                            <th>Date</th>
                            <th>View</th>
                            {user?.userType === USER_TYPE_CONSTANT.TECHNICIAN && <th>Start</th>}
                            {user?.userType !== USER_TYPE_CONSTANT.TECHNICIAN && <th>Surgical Report</th>}
                            {user?.userType !== USER_TYPE_CONSTANT.TECHNICIAN && <th>Surgery Dashboard</th>}
                        </tr>
                        {list.map((e, i) => {
                            return (<>
                                <tr id={e?._id} data-ng-repeat="customer in people | filter: table">
                                    <td>{i + 1}</td>
                                    <td>{e?.patient_details?.patient_initials}</td>
                                    {user?.userType === USER_TYPE_CONSTANT.ADMIN && <td>{e?.tech_name}</td>}
                                    <td>{moment(e?.date).format("DD MMMM YYYY h:mm:ss a")}</td>
                                    <td>
                                        <i className="bi bi-eye cursor-pointer" onClick={() => { handle.viewPatientDetail(e?._id) }}></i>
                                    </td>
                                    {user?.userType === USER_TYPE_CONSTANT.TECHNICIAN && <td>
                                        <i className="bi bi-arrow-right cursor-pointer" onClick={() => { handle.start(e?._id, e?.activation_status) }}></i>
                                    </td>}
                                    {user?.userType !== USER_TYPE_CONSTANT.TECHNICIAN && <td>
                                        <i className="bi bi-arrow-right cursor-pointer" onClick={() => { handle.summaryReport(e?._id) }}></i>
                                    </td>}
                                    {user?.userType !== USER_TYPE_CONSTANT.TECHNICIAN && <td>
                                        <i className="bi bi-arrow-right cursor-pointer" onClick={() => { handle.surgicalReport(e?._id) }}></i>
                                    </td>}
                                </tr>
                            </>)
                        })}
                    </table>
                        <Pagination
                            className="pagination-bar"
                            currentPage={pagination.currentPage}
                            totalRecords={totalRecords}
                            limit={pagination.limit}
                            onPageChange={page => setPaginaion({ ...pagination, currentPage: page })}
                        />
                    </> : <PageNotFound title={"No Data Found!"} warning={"Look like you don't have any data"} text=" " />
                }


            </div>
        </>
    );
};

export default PatiantDetailList;