import socket from "../actions/socket";

export const useSocket = (cb) => {
    return {
        on: () => {
            socket.on("setHairRecord", data => cb(data,"setHairRecord"))
            socket.on("getHairRecord", data => cb(data,"getHairRecord"))
            socket.on("getGraftCount", data => cb(data,"getGraftCount"))
        },
        off: () => {
            socket.off("setHairRecord")
            socket.off("getHairRecord")
            socket.off("getGraftCount")
        },
        refresh: () => {
            socket.off("setHairRecord")
            socket.off("getHairRecord")
            socket.off("getGraftCount")
            socket.on("setHairRecord", data => cb(data,"setHairRecord"))
            socket.on("getHairRecord", data => cb(data,"getHairRecord"))
            socket.on("getGraftCount", data => cb(data,"getGraftCount"))
        },
    }
}