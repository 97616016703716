import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "../api/axios";
import { API_URL, DEFAULT_ERROR, NAVIGATE_LNK, USER_TYPE_CONSTANT } from "../common/constants";
import PageNotFound from "../component/PageNotFound";

const Dashboard = () => {

    const navigate = useNavigate()

    const user = useSelector(e => e.auth.user);

    const [isLoading, setIsLoading] = useState(false);
    const [list, setList] = useState([])

    const handle = {
        view: (_id) => {
            if (user?.userType !== USER_TYPE_CONSTANT.TECHNICIAN) navigate(`${NAVIGATE_LNK.SUMURY_REPORT}/${_id}`)
        },
        surgicalReport: (_id) => {
            if (user?.userType !== USER_TYPE_CONSTANT.TECHNICIAN) navigate(`${NAVIGATE_LNK.SURGICAL_DASHBOARD}/${_id}`)
        },
    }

    const fetchList = () => {
        axios.get(`${API_URL.RUNNING_SURGERY_LIST}/${user._id}`)
            .then((response => {
                setIsLoading(false)
                if (response?.data?.data) {
                    setList(response?.data?.data)
                } else if (typeof response?.data?.data === 'string') {
                    toast.error(response?.data?.data)
                }
                else {
                    toast.error(DEFAULT_ERROR)
                }

            })).catch((err) => {
                setIsLoading(false)
                if (err?.response) {
                    toast.error(err?.response?.data?.data?.message || DEFAULT_ERROR)
                };
            })
    }

    useEffect(() => {
        setIsLoading(true)
        fetchList()
    }, [])

    return (
        <>
            <div className="container-fluid">
                <div className="line">
                    <span>Running Surgeries</span>
                </div>
                {isLoading && <div className="center-screen"> <ThreeDots color="#0000cd" /> </div>}
                {!isLoading && list?.length > 0 &&
                    <table>
                        <tr>
                            <th>No</th>
                            <th>Patient Name</th>
                            <th>Doctor Name</th>
                            <th>Surgeon Name</th>
                            <th>Extraction Start At</th>
                            <th>Placing Start At</th>
                            <th>View</th>
                            {user?.userType !== USER_TYPE_CONSTANT.TECHNICIAN && <th>Surgery Dashboard</th>}
                        </tr>
                        {list?.map((e, i) => {
                            return (<>
                                <tr id={e?._id} data-ng-repeat="customer in people | filter: table">
                                    <td>{i + 1}</td>
                                    <td>{e?.patient_details?.patient_initials || '-'}</td>
                                    <td>{e?.tech_name || '-'}</td>
                                    <td>{e?.surgical_details?.surgeon || '-'}</td>
                                    <td>{typeof e?.sx_Start_Time === 'string' ? moment(e?.sx_Start_Time).format("DD MMMM YYYY h:mm:ss a") : '-'}</td>
                                    <td>{typeof e?.extraction_Start === 'string' ? moment(e?.extraction_Start).format("DD MMMM YYYY h:mm:ss a") : '-'}</td>
                                    <td>
                                        <i className="bi bi-eye cursor-pointer" onClick={() => { handle.view(e?._id) }}></i>
                                    </td>
                                    {user?.userType !== USER_TYPE_CONSTANT.TECHNICIAN && <td>
                                        <i className="bi bi-arrow-right cursor-pointer" onClick={() => { handle.surgicalReport(e?._id) }}></i>
                                    </td>}
                                </tr>
                            </>)
                        })}
                    </table>}
                {!list?.length && <PageNotFound title={"No Data Found!"} warning={"Look like you don't have any running surgeries"} text=" " />}
            </div>
        </>
    );
};

export default Dashboard;