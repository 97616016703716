import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "../api/axios";
import { API_URL, DEFAULT_ERROR, NAVIGATE_LNK, SERVER_BASE_URL, toHHMMSS } from "../common/constants";
import '../styles/surgicalDashboard.css'
import _ from 'lodash'
import Timer from "../component/Timer";
import GaugeChart from "../component/GaugeChart";
import moment from "moment";
import { useSocket } from "../hooks/useSocket";

const SurgicalDashboard = () => {
    const { _id } = useParams();
    const user = useSelector(e => e?.auth?.user);
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({})
    const [patientDetails, setPatientDetails] = useState({})


    const { on, off, refresh } = useSocket((res, event) => {
        console.log(res, event)
        switch (event) {
            case "getHairRecord":
                if (res) {
                    console.log("[res] :: getHairRecord :: ", res)
                    let { patient_id, data } = res;
                    if (patient_id === _id && data?.surgeryDashboard) {
                        setData(data?.surgeryDashboard)
                    }
                }
                break;
            default:
                break;
        }
    })

    useEffect(() => {
        refresh()
        return () => off()
    }, [])

    useEffect(() => {
        setIsLoading(true)
        axios.post(`${API_URL.SURGICAL_DASHBOARD}/${user?._id}`, { patient_id: _id })
            .then((response => {
                setIsLoading(false)
                if (response?.data) {
                    setData(response?.data)
                } else if (typeof response?.data === 'string') {
                    toast.error(response?.data)
                }
                else {
                    toast.error(DEFAULT_ERROR)
                }

            })).catch((err) => {
                setIsLoading(false)
                if (err?.response) {
                    toast.error(err?.response?.data?.data?.message || DEFAULT_ERROR)
                };
            })

        fetchPatientDetail()
    }, [_id])


    const fetchPatientDetail = () => {

        axios.get(`${API_URL.VIEW_PATIANT_DETAILS}/${_id}`)
            .then((response => {
                if (response?.data?.data) {
                    setPatientDetails(response?.data?.data)
                } else if (typeof response?.data?.data === 'string') {
                    toast.error(response?.data?.data)
                }
                else {
                    toast.error(DEFAULT_ERROR)
                }

            })).catch((err) => {
                if (err?.response) {
                    toast.error(err?.response?.data?.data?.message || DEFAULT_ERROR)
                };
            })
    }

    const handle = {
        viewReport: () => {
            if (patientDetails?.activation_status === 2) navigate(`${NAVIGATE_LNK.SUMURY_REPORT}/${_id}`)
        },
        mailReport: () => {
            axios.post(`${API_URL.GENRATE_PDF}/${user?._id}`, { patientId: _id })
                    .then((response => {
                        setIsLoading(false)
                        if (response?.data?.data?.message) {
                            toast.success(response?.data?.data?.message)
                        } else if (typeof response?.data === 'string') {
                            toast.error(response?.data)
                        }
                        else {
                            toast.error(DEFAULT_ERROR)
                        }

                    })).catch((err) => {
                        setIsLoading(false)
                        if (err?.response) {
                            toast.error(err?.response?.data?.data?.message || DEFAULT_ERROR)
                        };
                    })
        },
        startSxTime: (tag) => {
            if (!patientDetails?.finish_Time) {

                setIsLoading(true)
                axios.post(`${API_URL.SX_TIME}/${_id}`, { tag })
                    .then((response => {
                        setIsLoading(false)
                        if (response?.data?.data?.message) {
                            fetchPatientDetail()
                            toast.success(response?.data?.data?.message)
                        } else if (typeof response?.data === 'string') {
                            toast.error(response?.data)
                        }
                        else {
                            toast.error(DEFAULT_ERROR)
                        }

                    })).catch((err) => {
                        setIsLoading(false)
                        if (err?.response) {
                            toast.error(err?.response?.data?.data?.message || DEFAULT_ERROR)
                        };
                    })
            }
        },
        startExtrationTime: (tag) => {
            if (!patientDetails?.extraction_Finish) {

                setIsLoading(true)
                axios.post(`${API_URL.EXTRACTION_TIME}/${_id}`, { tag })
                    .then((response => {
                        setIsLoading(false)
                        if (response?.data?.data?.message) {
                            fetchPatientDetail()
                            toast.success(response?.data?.data?.message)
                        } else if (typeof response?.data === 'string') {
                            toast.error(response?.data)
                        }
                        else {
                            toast.error(DEFAULT_ERROR)
                        }

                    })).catch((err) => {
                        setIsLoading(false)
                        if (err?.response) {
                            toast.error(err?.response?.data?.data?.message || DEFAULT_ERROR)
                        };
                    })
            }
        },
        startPlacingTime: (tag) => {
            if (!patientDetails?.placing_Finish) {
                setIsLoading(true)
                axios.post(`${API_URL.PLACING_TIME}/${_id}`, { tag })
                    .then((response => {
                        setIsLoading(false)
                        if (response?.data?.data?.message) {
                            fetchPatientDetail()
                            toast.success(response?.data?.data?.message)
                        } else if (typeof response?.data === 'string') {
                            toast.error(response?.data)
                        }
                        else {
                            toast.error(DEFAULT_ERROR)
                        }

                    })).catch((err) => {
                        setIsLoading(false)
                        if (err?.response) {
                            toast.error(err?.response?.data?.data?.message || DEFAULT_ERROR)
                        };
                    })
            }
        },
        updatePatientDetails: () => {
            navigate(`${NAVIGATE_LNK.PATIANT_DETAIL}/${_id}`)
        },
        updatTechDetails: () => {
            navigate(`${NAVIGATE_LNK.TECH_BY_DATA}/${_id}`)
        }
    }

    return (
        <div className="container-fluid">
            {isLoading ? <div className="center-screen"> <ThreeDots color="#0000cd" /> </div> :
                <>
                    <div className="line">
                        <div className="line">
                            <h4 >Surgical Dashboard</h4>
                            <div className="float-right">
                                <button type="button" className="btn btn-outline-primary btn-sm" onClick={handle.viewReport}  >View Report</button>
                            </div>
                            <div className="float-right">
                                <button type="button" className="btn btn-outline-primary btn-sm" onClick={handle.mailReport}  >Mail Report</button>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        {/* <div className="card" style={{ width: '20rem' }}> */}
                        <div className="card" style={{ width: '23rem', fontSize: '28px' }}>
                            <div className="card-body">
                                <tr>
                                    <td><h4 className="card-text">SURGERY GOAL:</h4> </td><td> <h4> {data?.surgery_goal}</h4></td>
                                </tr>
                                <tr>
                                    <td><h4 className="card-text">GRAFT COUNT:</h4> </td><td> <h4> {data?.graftsCount || '-'}</h4></td>
                                </tr>
                                <tr>
                                    <td><h4 className="card-text">SINGLE HAIR COUNT:</h4> </td><td> <h4> {data?.singleGraftsCount || '-'}</h4></td>
                                </tr>
                                <tr>
                                    <td><h4 className="card-text">TOTAL HAIR COUNT:</h4> </td><td> <h4> {data?.totalHairCount || '-'}</h4></td>
                                </tr>
                                <tr>
                                    <td><h4 className="card-text">STRANS. RATE HAIR:</h4> </td><td> <h4> {data?.transactionRateHair || '-'}%</h4></td>
                                </tr>
                                <tr>
                                    <td><h4 className="card-text">TRANS. RATE GRAFT:</h4> </td><td> <h4> {data?.transactionRateGrafts || '-'}%</h4></td>
                                </tr>
                                <tr>
                                    <td><h4 className="card-text">SURGERY DURATION:</h4> </td><td> <h4> {data?.surgeryDuration ? toHHMMSS(data?.surgeryDuration / 1000) : '-'}</h4></td>
                                </tr>
                                <tr>
                                    <td><h4 className="card-text">GPH:</h4> </td><td> <h4> {data?.graftsPerHour || '-'}</h4></td>
                                </tr>
                                {/* <h5 className="card-text">SURGERY GOAL :     {data?.surgery_goal}</h5>
                                <h5 className="card-text">GRAFT COUNT :      {data?.graftsCount || '-'}</h5>
                                <h5 className="card-text">SINGLE HAIR COUNT :{data?.singleGraftsCount || '-'}</h5>
                                <h5 className="card-text">TOTAL HAIR COUNT : {data?.totalHairCount || '-'}</h5>
                                <h5 className="card-text">TRANS. RATE HAIR:  {data?.transactionRateHair || '-'}%</h5>
                                <h5 className="card-text">TRANS. RATE GRAFT: {data?.transactionRateGrafts || '-'}%</h5>
                                <h5 className="card-text">SURGERY DURATION : {data?.surgeryDuration ? toHHMMSS(data?.surgeryDuration / 1000) : '-'}</h5>
                                <h5 className="card-text">GPH : {data?.graftsPerHour || '-'}</h5> */}
                            </div>
                        </div>
                        <GaugeChart chartValue={data?.graftsCount >= (data?.surgery_goal ? data?.surgery_goal === "<500" ? 500 : (data?.surgery_goal.charAt(data?.surgery_goal.length - 1) === "+" ? data?.surgery_goal?.split('+')?.[0] : data?.surgery_goal?.split('-')?.[1]) : 0) ? (data?.surgery_goal ? data?.surgery_goal === "<500" ? 500 : (data?.surgery_goal.charAt(data?.surgery_goal.length - 1) === "+" ? data?.surgery_goal?.split('+')?.[0] : data?.surgery_goal?.split('-')?.[1]) : 0) : data?.graftsCount} max={data?.surgery_goal ? data?.surgery_goal === "<500" ? 500 : (data?.surgery_goal.charAt(data?.surgery_goal.length - 1) === "+" ? data?.surgery_goal?.split('+')?.[0] : data?.surgery_goal?.split('-')?.[1]) : 0} />
                        {data?.logo && <img className="doctor-log" crossorigin="anonymous" src={`${SERVER_BASE_URL}${data?.logo}`} alt="" width="50px" height="50px" />}
                    </div>
 
                    <div className="row">
                        </div>

                    <div className="row">
                        <div className="col sumury">
                            <div className="row">
                                <div className="card border-danger mb-3" style={{ width: '12rem' }}>
                                    <div className="card-body text-danger" onClick={() => handle.startSxTime(patientDetails?.sx_Start_Time ? 1 : 0)}>
                                        <p className="card-text">{`${patientDetails?.sx_Start_Time && patientDetails?.finish_Time ? 'Total' : patientDetails?.sx_Start_Time && !patientDetails?.finish_Time ? 'Stop' : 'Start'}`}</p>
                                        <p className="card-text">Surgery</p>
                                        <p className="card-text">Timer</p>
                                        {patientDetails?.sx_Start_Time!==undefined && patientDetails?.sx_Start_Time!==0 && patientDetails?.sx_Start_Time !== "" && (patientDetails?.finish_Time===0 || !patientDetails?.finish_Time) && <Timer startTime={new Date(patientDetails?.sx_Start_Time)} endTime={new Date()} />}
                                        {patientDetails?.sx_Start_Time!==0 && patientDetails?.sx_Start_Time !== "" && patientDetails?.finish_Time !== 0 && patientDetails?.total_Sx_Time > 0 && <p>{toHHMMSS(patientDetails?.total_Sx_Time / 1000)}</p>}
                                    </div>
                                </div>
                                <div className="card border-danger mb-3" style={{ width: '12rem' }}>
                                    <div className="card-body text-danger" onClick={() => handle.startExtrationTime(patientDetails?.extraction_Start ? 1 : 0)}>
                                        <p className="card-text">{`${patientDetails?.extraction_Start && patientDetails?.extraction_Finish ? 'Total' : patientDetails?.extraction_Start && !patientDetails?.extraction_Finish ? 'Stop' : 'Start'}`}</p>
                                        <p className="card-text">Extraction</p>
                                        <p className="card-text">Timer</p>
                                        {patientDetails?.extraction_Start !== undefined && patientDetails?.extraction_Start !== 0 && patientDetails?.extraction_Start !== "" && (patientDetails?.extraction_Finish === 0 || !patientDetails?.extraction_Finish) && <Timer startTime={new Date(patientDetails?.extraction_Start)} endTime={new Date()} />}
                                        {patientDetails?.extraction_Start!==0 && patientDetails?.extraction_Start !== "" && patientDetails?.extraction_Finish !== 0 && patientDetails?.total_Extraction_Time > 0 && <p>{toHHMMSS(patientDetails?.total_Extraction_Time / 1000)}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="card border-success  mb-3" style={{ width: '12rem' }}>
                                    <div className="card-body text-success " onClick={handle.viewReport}>
                                        <p className="card-text">FINISH</p>
                                        <p className="card-text">SURGERY</p>
                                    </div>
                                </div>
                                <div className="card border-danger mb-3" style={{ width: '12rem' }}>
                                    <div className="card-body text-danger" onClick={() => handle.startPlacingTime(patientDetails?.placing_Start ? 1 : 0)}>
                                        <p className="card-text">{`${patientDetails?.placing_Start && patientDetails?.placing_Finish ? 'Total' : patientDetails?.placing_Start && !patientDetails?.placing_Finish ? 'Stop' : 'Start'}`}</p>
                                        <p className="card-text">Placing</p>
                                        <p className="card-text">Timer</p>
                                        {patientDetails?.placing_Start !== undefined && patientDetails?.placing_Start !== 0 && patientDetails?.placing_Start !== "" && (patientDetails?.placing_Finish === 0 || !patientDetails?.placing_Finish) && <Timer startTime={new Date(patientDetails?.placing_Start)} endTime={new Date()} />}
                                        {patientDetails?.placing_Start!==0 && patientDetails?.placing_Start !== "" && patientDetails?.placing_Finish !== 0 && patientDetails?.total_Placing_Time > 0 && <p>{toHHMMSS(patientDetails?.total_Placing_Time / 1000)}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="card border-warning mb-3" style={{ width: '12rem' }}>
                                    <div className="card-body text-warning" onClick={handle.updatePatientDetails}>
                                        <p className="card-text">EDIT DETAILS</p>
                                        <p className="card-text">PATIENT/SURGICAL</p>
                                    </div>
                                </div>
                                <div className="card border-warning mb-3" style={{ width: '12rem' }}>
                                    <div className="card-body text-warning" onClick={handle.updatTechDetails}>
                                        <p className="card-text">VIEW/EDIT</p>
                                        <p className="card-text">TECH DATA</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col center-sumury">
                            <div className="row">
                                <div className="card" style={{ width: '21rem' }}>
                                    <div className="card-body">
                                        <tr>
                                            <td><p className="card-text">FUE Start:</p> </td><td> <p> {data?.startTime ? moment(data?.startTime).format("MMMM Do YYYY, h:mm:ss a") : '-'}</p></td>
                                        </tr>
                                        <tr>
                                            <td><p className="card-text">FUE Finish:</p> </td><td> <p> {data?.finishTime ? moment(data?.finishTime).format("MMMM Do YYYY, h:mm:ss a") : '-'}</p></td>
                                        </tr>
                                        <tr>
                                            <td><p className="card-text">FUE Extract Time:</p> </td><td> <p> {data?.extractTime ? toHHMMSS(data?.extractTime/1000) : '-'}</p></td>
                                        </tr>
                                        <tr>
                                            <td><p className="card-text">Extraction Start:</p> </td><td> <p> {data?.extractionStartTime ? moment(data?.extractionStartTime).format("MMMM Do YYYY, h:mm:ss a") : '-'}</p></td>
                                        </tr>
                                        <tr>
                                            <td><p className="card-text">Extraction Finish:</p> </td><td> <p> {data?.extractionFinishTime ? moment(data?.extractionFinishTime).format("MMMM Do YYYY, h:mm:ss a") : '-'}</p></td>
                                        </tr>
                                        <tr>
                                            <td><p className="card-text">Extraction Time:</p> </td><td> <p> {data?.extractionTime ? toHHMMSS(data?.extractionTime/1000) : '-'}</p></td>
                                        </tr>
                                        <tr>
                                            <td><p className="card-text">Placing Start:</p> </td><td> <p> {data?.placingStartTime ? moment(data?.placingStartTime).format("MMMM Do YYYY, h:mm:ss a") : '-'}</p></td>
                                        </tr>
                                        <tr>
                                            <td><p className="card-text">Placing Finish:</p> </td><td> <p>{data?.placingFinishTime ? moment(data?.placingFinishTime).format("MMMM Do YYYY, h:mm:ss a") : '-'}</p></td>
                                        </tr>
                                        <tr>
                                            <td><p className="card-text">Placing Time:</p> </td><td> <p> {data?.placingTime ? toHHMMSS(data?.placingTime/1000) : '-'}</p></td>
                                        </tr>
                                        {/* <p className="card-text">FUE Start : {data?.startTime ? moment(data?.startTime).format("MMMM Do YYYY, h:mm:ss a") : '-'}</p>
                                        <p className="card-text">FUE Finish : {data?.finishTime ? moment(data?.finishTime).format("MMMM Do YYYY, h:mm:ss a") : '-'}</p>
                                        <p className="card-text">FUE Extract Time : {data?.extractTime ?  toHHMMSS(data?.extractTime/1000) : '-'}</p>
                                        <p className="card-text">Extraction Start : {data?.extractionStartTime ? moment(data?.extractionStartTime).format("MMMM Do YYYY, h:mm:ss a") : '-'}</p>
                                        <p className="card-text">Extraction Finish : {data?.extractionFinishTime ? moment(data?.extractionFinishTime).format("MMMM Do YYYY, h:mm:ss a") : '-'}</p>
                                        <p className="card-text">Extraction Time : {data?.extractionTime ? toHHMMSS(data?.extractionTime/1000) : '-'}</p>
                                        <p className="card-text">Placing Start : {data?.placingStartTime ? moment(data?.placingStartTime).format("MMMM Do YYYY, h:mm:ss a") : '-'}</p>
                                        <p className="card-text">Placing Finish : {data?.placingFinishTime ? moment(data?.placingFinishTime).format("MMMM Do YYYY, h:mm:ss a") : '-'}</p>
                                        <p className="card-text">Placing Time : {data?.placingTime ? toHHMMSS(data?.placingTime/1000) : '-'}</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col tech-list">
                            {
                                _?.chunk((data?.allTechReports || []), 2)?.map(e => {
                                    return (<div className="row">
                                        {
                                            e?.map(t => {
                                                return (
                                                    <div className="card" style={{ width: '16rem' }}>
                                                        <h5 className="card-header">{t?.tech_name}</h5>
                                                        <div className="card-body">
                                                            <tr>
                                                                <td><p className="card-text">Graft Count:</p> </td><td> <p>  {t?.total_grafts}</p></td>
                                                            </tr>
                                                            <tr>
                                                                <td><p className="card-text">Hair Count:</p> </td><td> <p> {t?.total_hairCont}</p></td>
                                                            </tr>
                                                            <tr>
                                                                <td><p className="card-text">Potential Hair:</p> </td><td> <p> {t?.total_PotentialHair}</p></td>
                                                            </tr>
                                                            <tr>
                                                                <td><p className="card-text">Trans Hair:</p> </td><td> <p> {t?.transaction_rate_hair}%</p></td>
                                                            </tr>
                                                            <tr>
                                                                <td><p className="card-text">Trans Graft:</p> </td><td> <p> {t?.transaction_rate_grafts}%</p></td>
                                                            </tr>
                                                            {/* <p className="card-text">Graft Count : {t?.total_grafts}</p>
                                                            <p className="card-text">Hair Count : {t?.total_hairCont}</p>
                                                            <p className="card-text">Potential Hair : {t?.total_PotentialHair}</p>
                                                            <p className="card-text">Trans Hair : {t?.transaction_rate_hair}%</p>
                                                            <p className="card-text">Trans Graft : {t?.transaction_rate_grafts}%</p> */}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>)
                                })
                            }
                        </div>
                    </div>
                </>}
        </div>

    );
};

export default SurgicalDashboard;