import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { UPDATE_PATIANT_DETAIL } from "../actions/types";
import axios from "../api/axios";
import { API_URL, DEFAULT_ERROR, NAVIGATE_LNK, USER_TYPE_CONSTANT } from "../common/constants";
import { toast } from 'react-toastify';
import { ThreeDots } from "react-loader-spinner";
import DatePicker from "react-datepicker";
import Select from "../component/Select";

const PatiantDetail = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const _PD = useSelector((e) => e.patiantDetail);
    const user = useSelector((e) => e.auth.user);
    const { _id } = useParams()
    const [view, setView] = useState(1)
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [hairColorList, setHairColorList] = useState([])
    const [hairCaliberList, setHairCaliberList] = useState([])
    const [hairTypeList, setHairTypeList] = useState([])
    const [skinColorList, setSkinColorList] = useState([])
    const [deviceUsedList, setDeviceUsedList] = useState([])
    const [placingDeviceUsedList, setPlacingDeviceUsedList] = useState([])
    const [deviceSurgeonList, setDeviceSurgeonList] = useState([])
    const [tipStyleList, setTipStyleList] = useState([])
    const [tipSizeList, setTipSizeList] = useState([])
    const [holdingSolutionList, setHoldingSolutionList] = useState([])
    const [surgeryGoalsList, setSurgeryGoalsList] = useState([])

    useEffect(() => {
        const initialStats = JSON.parse(JSON.stringify(_PD));
        setInputText(initialStats)
    }, [_PD])

    useEffect(() => {
        axios.get('gethairtype')
            .then((response => {
                if (response?.data) {
                    setHairTypeList(response?.data?.map(e => ({ ...e, value: e?.name })))
                } else if (typeof response?.data?.data === 'string') {
                    toast.error(response?.data?.data)
                }
                else {
                    toast.error(DEFAULT_ERROR)
                }

            })).catch((err) => {
                if (err?.response) {
                    toast.error(err?.response?.data?.data?.message || DEFAULT_ERROR)
                };
            })
        axios.get('gethaircolor')
            .then((response => {
                if (response?.data) {
                    setHairColorList(response?.data?.map(e => ({ ...e, value: e?.name })))
                } else if (typeof response?.data?.data === 'string') {
                    toast.error(response?.data?.data)
                }
                else {
                    toast.error(DEFAULT_ERROR)
                }

            })).catch((err) => {
                if (err?.response) {
                    toast.error(err?.response?.data?.data?.message || DEFAULT_ERROR)
                };
            })
        axios.get('gethaircaliber')
            .then((response => {
                if (response?.data) {
                    setHairCaliberList(response?.data?.map(e => ({ ...e, value: e?.name })))
                } else if (typeof response?.data?.data === 'string') {
                    toast.error(response?.data?.data)
                }
                else {
                    toast.error(DEFAULT_ERROR)
                }

            })).catch((err) => {
                if (err?.response) {
                    toast.error(err?.response?.data?.data?.message || DEFAULT_ERROR)
                };
            })
        axios.get('getskincolor')
            .then((response => {
                if (response?.data) {
                    setSkinColorList(response?.data?.map(e => ({ ...e, value: e?.name })))
                } else if (typeof response?.data?.data === 'string') {
                    toast.error(response?.data?.data)
                }
                else {
                    toast.error(DEFAULT_ERROR)
                }

            })).catch((err) => {
                if (err?.response) {
                    toast.error(err?.response?.data?.data?.message || DEFAULT_ERROR)
                };
            })
        axios.get('getdeviceused')
            .then((response => {
                if (response?.data) {
                    setDeviceUsedList(response?.data?.map(e => ({ ...e, value: e?.name })))
                } else if (typeof response?.data?.data === 'string') {
                    toast.error(response?.data?.data)
                }
                else {
                    toast.error(DEFAULT_ERROR)
                }

            })).catch((err) => {
                if (err?.response) {
                    toast.error(err?.response?.data?.data?.message || DEFAULT_ERROR)
                };
            })
        axios.get('getplacersused')
            .then((response => {
                if (response?.data) {
                    setPlacingDeviceUsedList(response?.data?.map(e => ({ ...e, value: e?.name })))
                } else if (typeof response?.data?.data === 'string') {
                    toast.error(response?.data?.data)
                }
                else {
                    toast.error(DEFAULT_ERROR)
                }

            })).catch((err) => {
                if (err?.response) {
                    toast.error(err?.response?.data?.data?.message || DEFAULT_ERROR)
                };
            })
        axios.get('getsurgeon')
            .then((response => {
                if (response?.data) {
                    setDeviceSurgeonList(response?.data?.map(e => ({ ...e, value: e?.name })))
                } else if (typeof response?.data?.data === 'string') {
                    toast.error(response?.data?.data)
                }
                else {
                    toast.error(DEFAULT_ERROR)
                }

            })).catch((err) => {
                if (err?.response) {
                    toast.error(err?.response?.data?.data?.message || DEFAULT_ERROR)
                };
            })
        axios.get('gettipstyle')
            .then((response => {
                if (response?.data) {
                    setTipStyleList(response?.data?.map(e => ({ ...e, value: e?.name })))
                } else if (typeof response?.data?.data === 'string') {
                    toast.error(response?.data?.data)
                }
                else {
                    toast.error(DEFAULT_ERROR)
                }

            })).catch((err) => {
                if (err?.response) {
                    toast.error(err?.response?.data?.data?.message || DEFAULT_ERROR)
                };
            })
        axios.get('gettipsize')
            .then((response => {
                if (response?.data) {
                    setTipSizeList(response?.data?.map(e => ({ ...e, value: e?.name })))
                } else if (typeof response?.data?.data === 'string') {
                    toast.error(response?.data?.data)
                }
                else {
                    toast.error(DEFAULT_ERROR)
                }

            })).catch((err) => {
                if (err?.response) {
                    toast.error(err?.response?.data?.data?.message || DEFAULT_ERROR)
                };
            })
        axios.get('getholdingsolution')
            .then((response => {
                if (response?.data) {
                    setHoldingSolutionList(response?.data?.map(e => ({ ...e, value: e?.name })))
                } else if (typeof response?.data?.data === 'string') {
                    toast.error(response?.data?.data)
                }
                else {
                    toast.error(DEFAULT_ERROR)
                }

            })).catch((err) => {
                if (err?.response) {
                    toast.error(err?.response?.data?.data?.message || DEFAULT_ERROR)
                };
            })
        axios.get('getsurgerygoals')
            .then((response => {
                if (response?.data) {
                    setSurgeryGoalsList(response?.data?.map(e => ({ ...e, value: e?.name })))
                } else if (typeof response?.data?.data === 'string') {
                    toast.error(response?.data?.data)
                }
                else {
                    toast.error(DEFAULT_ERROR)
                }

            })).catch((err) => {
                if (err?.response) {
                    toast.error(err?.response?.data?.data?.message || DEFAULT_ERROR)
                };
            })
    }, [])

    const viewText = {
        1: { text: `${_id ? 'Edit' : 'Insert'} Patient Details`, next: "Next Step Surgical Details" },
        2: { text: `${_id ? 'Edit' : 'Insert'} Surgical Details`, next: "Next Surgical Summary", prev1: 'Back To Patient Details' },
        3: { text: 'Surgical Summary', next: "Next Start Surgery", prev1: 'Back To Patient Details', prev2: "Back To Surgical Summary" }
    }

    const initialStats = {
        patiantInitials: "",
        patiantDob: new Date(),
        hairColor: "",
        hairCaliber: "",
        hairType: "",
        skinColor: "",
        dateOfSX: new Date(),
        surgeon: "",
        surgicalGoal: "",
        fueDeviceUsed: "",
        fueTipStyle: "",
        fueTipSize: "",
        holdingSolution: "",
        placingDeviceUsed: "",
    }
    const [inputText, setInputText] = useState(initialStats);
    const [patientDetails, setPatientDetails] = useState({})

    useEffect(() => {
        if (_id) {
            axios.get(`${API_URL.VIEW_PATIANT_DETAILS}/${_id}`)
                .then((response => {
                    if (response?.data?.data) {
                        setPatientDetails(response?.data?.data)
                        const { patient_details, surgical_details } = response?.data?.data
                        const payload = {
                            patiantInitials: patient_details?.patient_initials,
                            patiantDob: patient_details?.patient_DOB,
                            hairColor: patient_details?.hair_color,
                            hairCaliber: patient_details?.hair_caliber,
                            hairType: patient_details?.hair_type,
                            skinColor: patient_details?.skin_color,
                            dateOfSX: patient_details?.date_of_sx,
                            surgeon: surgical_details?.surgeon,
                            surgicalGoal: surgical_details?.surgical_goal,
                            fueDeviceUsed: surgical_details?.device_used,
                            fueTipStyle: surgical_details?.tip_style,
                            fueTipSize: surgical_details?.tip_size,
                            holdingSolution: surgical_details?.holding_solution,
                            placingDeviceUsed: surgical_details?.placing_device_used,
                        }
                        dispatch({ type: UPDATE_PATIANT_DETAIL, payload })
                    } else if (typeof response?.data?.data === 'string') {
                        toast.error(response?.data?.data)
                    }
                    else {
                        toast.error(DEFAULT_ERROR)
                    }

                })).catch((err) => {
                    if (err?.response) {
                        toast.error(err?.response?.data?.data?.message || DEFAULT_ERROR)
                    };
                })
        }
    }, [_id])

    const handle = {
        changeView: (e) => {
            if (user?.userType === USER_TYPE_CONSTANT.TECHNICIAN) return;
            let payload = {}
            let keys = []
            if (view === 1) {
                keys = ["patiantInitials", "patiantDob", "hairColor", "hairCaliber", "hairType", "skinColor", "dateOfSX"]

            }
            else if (view === 2) {
                keys = ["surgeon", "surgicalGoal", "fueDeviceUsed", "fueTipStyle", "fueTipSize", "holdingSolution", "placingDeviceUsed"]
            }
            setError("");
            if (e > view && keys.some(e => inputText[e]?.length < 1)) return setError("Please enter all field!")
            keys.map(e => payload[e] = inputText[e]);


            if (e === 4) {
                payload = initialStats
                setIsLoading(true)

                const url = _id ? `${API_URL.UPDATE_PATIENT_DETAILS}/${_id}` : `${API_URL.ADD_PATIENT_DETAILS}/${user?._id}`
                if (_id) {
                    const PD = JSON.parse(JSON.stringify(patientDetails));
                    delete PD._id;
                    const requestData = {
                        patient_details: {
                            "patient_initials": inputText?.patiantInitials,
                            "patient_DOB": inputText?.patiantDob,
                            "hair_color": inputText?.hairColor,
                            "hair_caliber": inputText?.hairCaliber,
                            "hair_type": inputText?.hairType,
                            "skin_color": inputText?.skinColor,
                            "date_of_sx": inputText?.dateOfSX,
                        },
                        surgical_details: {
                            "surgeon": inputText?.surgeon,
                            "surgical_goal": inputText?.surgicalGoal,
                            "device_used": inputText?.fueDeviceUsed,
                            "tip_style": inputText?.fueTipStyle,
                            "tip_size": inputText?.fueTipSize,
                            "holding_solution": inputText?.holdingSolution,
                            "placing_device_used": inputText?.placingDeviceUsed,
                        }
                    }
                    axios.put(url, { ...PD, ...requestData })
                        .then((response => {
                            setInputText(initialStats);
                            setIsLoading(false)
                            if (response?.data?.data?.message) {
                                navigate(`${NAVIGATE_LNK.SURGICAL_DASHBOARD}/${_id}`);
                                toast.success(response?.data?.data?.message)
                            } else if (typeof response?.data?.data === 'string') {
                                setError(response?.data?.data)
                            }
                            else {
                                setError(DEFAULT_ERROR)
                            }

                        })).catch((err) => {
                            setInputText(initialStats);
                            setIsLoading(false)
                            if (err?.response) {
                                setError(err?.response?.data?.data?.message || DEFAULT_ERROR)
                            };
                        })
                }
                else {
                    const requestData = {
                        "patient_initials": inputText?.patiantInitials,
                        "patient_DOB": inputText?.patiantDob,
                        "hair_color": inputText?.hairColor,
                        "hair_caliber": inputText?.hairCaliber,
                        "hair_type": inputText?.hairType,
                        "skin_color": inputText?.skinColor,
                        "date_of_sx": inputText?.dateOfSX,
                        "surgeon": inputText?.surgeon,
                        "surgical_goal": inputText?.surgicalGoal,
                        "device_used": inputText?.fueDeviceUsed,
                        "tip_style": inputText?.fueTipStyle,
                        "tip_size": inputText?.fueTipSize,
                        "holding_solution": inputText?.holdingSolution,
                        "placing_device_used": inputText?.placingDeviceUsed,
                    }
                    axios.post(url, requestData)
                        .then((response => {
                            setInputText(initialStats);
                            setIsLoading(false)
                            if (response?.data?.data?.patient_details) {
                                navigate(`${NAVIGATE_LNK.SURGICAL_DASHBOARD}/${response?.data?.data?._id}`);
                                toast.success("Patient details added successfully")
                            } else if (typeof response?.data?.data === 'string') {
                                setError(response?.data?.data)
                            }
                            else {
                                setError(DEFAULT_ERROR)
                            }

                        })).catch((err) => {
                            setInputText(initialStats);
                            setIsLoading(false)
                            if (err?.response) {
                                setError(err?.response?.data?.data?.message || DEFAULT_ERROR)
                            };
                        })
                }
            }

            dispatch({ type: UPDATE_PATIANT_DETAIL, payload })
            if (e !== 4) setView(e)

        },
        changeInput: (event) => {
            const { name, value } = event.target;
            setError("");
            setInputText((oldValues) => {
                return {
                    ...oldValues,
                    [name]: value
                }
            });
        }
    }

    return (
        <>
            <div className="card-body">

                <h2 className="heading-small text-muted mb-4">{viewText[view].text}</h2>

                {isLoading && <div className="center-screen"> <ThreeDots color="#0000cd" /> </div>}

                {!isLoading && <>

                    <small className="error">{error}</small>
                    {view === 1 && <>
                        <div className="pl-lg-4">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group focused">
                                        <label className="form-control-label" for="patiantInitials" >Patient Initials</label>
                                        <input type="text" id="patiantInitials" name="patiantInitials" className="form-control form-control-alternative" placeholder="Patient Initials" onChange={handle.changeInput} value={inputText?.patiantInitials} />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label className="form-control-label" for="patiantDob">Patient DOB</label>
                                        <DatePicker className="form-control" dropdownMode="select" showMonthDropdown showYearDropdown selected={new Date(inputText?.patiantDob)} onChange={(date) => handle.changeInput({ target: { name: 'patiantDob', value: date } })} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group focused">
                                        <label className="form-control-label" for="hairColor">Hair Color</label>
                                        <Select className="form-control form-control-alternative"
                                            id="hairColor"
                                            onChange={handle.changeInput}
                                            value={inputText?.hairColor}
                                            label="Hair Color"
                                            options={hairColorList}
                                        />
                                        {/* <input type="text" id="hairColor" name="hairColor" className="form-control form-control-alternative" placeholder="Hair Color" onChange={handle.changeInput} value={inputText?.hairColor} /> */}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group focused">
                                        <label className="form-control-label" for="hairCaliber">Hair Caliber</label>
                                        <Select className="form-control form-control-alternative"
                                            id="hairCaliber"
                                            onChange={handle.changeInput}
                                            value={inputText?.hairCaliber}
                                            label="Hair Caliber"
                                            options={hairCaliberList}
                                        />
                                        {/* <input type="text" id="hairCaliber" name="hairCaliber" className="form-control form-control-alternative" placeholder="Hair Caliber" onChange={handle.changeInput} value={inputText?.hairCaliber} /> */}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group focused">
                                        <label className="form-control-label" for="hairType">Hair Type</label>
                                        <Select className="form-control form-control-alternative"
                                            id="hairType"
                                            onChange={handle.changeInput}
                                            value={inputText?.hairType}
                                            label="Hair Type"
                                            options={hairTypeList}
                                        />
                                        {/* <input type="text" id="hairType" name="hairType" className="form-control form-control-alternative" placeholder="Hair Type" onChange={handle.changeInput} value={inputText?.hairType} /> */}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group focused">
                                        <label className="form-control-label" for="skinColor">Skin Color</label>
                                        <Select className="form-control form-control-alternative"
                                            id="skinColor"
                                            onChange={handle.changeInput}
                                            value={inputText?.skinColor}
                                            label="Skin Color"
                                            options={skinColorList}
                                        />
                                        {/* <input type="text" id="skinColor" name="skinColor" className="form-control form-control-alternative" placeholder="Skin Color" onChange={handle.changeInput} value={inputText?.skinColor} /> */}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group focused">
                                        <label className="form-control-label" for="dateOfSX">Date Of SX</label>
                                        <DatePicker className="form-control" dropdownMode="select" showMonthDropdown showYearDropdown selected={new Date(inputText?.dateOfSX)} onChange={(date) => handle.changeInput({ target: { name: 'dateOfSX', value: date } })} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>}

                    {view === 3 && <>
                        <hr className="my-4" />
                        <h6 className="heading-small text-muted mb-4">Patient Details</h6>
                        <hr className="my-4" />
                        <div className="pl-lg-4">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group focused">
                                        <label className="form-control-label" for="patiantInitials" >Patient Initials</label>
                                        <input disabled={true} type="text" id="patiantInitials" className="form-control form-control-alternative" placeholder="Patient Initals" value={inputText.patiantInitials} />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label className="form-control-label" for="patiantDob">Patient DOB</label>
                                        <input disabled={true} type="text" id="patiantDob" className="form-control form-control-alternative" placeholder="Patient DOB" value={inputText.patiantDob} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group focused">
                                        <label className="form-control-label" for="hairColor">Hair Color</label>
                                        <input disabled={true} type="text" id="hairColor" className="form-control form-control-alternative" placeholder="Hair Color" value={inputText.hairColor} />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group focused">
                                        <label className="form-control-label" for="hairCaliber">Hair Caliber</label>
                                        <input disabled={true} type="text" id="hairCaliber" className="form-control form-control-alternative" placeholder="Hair Caliber" value={inputText.hairCaliber} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group focused">
                                        <label className="form-control-label" for="hairType">Hair Type</label>
                                        <input disabled={true} type="text" id="hairType" className="form-control form-control-alternative" placeholder="Hair Type" value={inputText.hairType} />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group focused">
                                        <label className="form-control-label" for="skinColor">Skin Color</label>
                                        <input disabled={true} type="text" id="skinColor" className="form-control form-control-alternative" placeholder="Skin Color" value={inputText.skinColor} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group focused">
                                        <label className="form-control-label" for="dateOfSX">Date Of SX</label>
                                        <input disabled={true} type="text" id="dateOfSX" className="form-control form-control-alternative" placeholder="Date Of SX" value={inputText.dateOfSX} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className="my-4" />
                        <h6 className="heading-small text-muted mb-4">Surgeon Details</h6>
                        <hr className="my-4" />
                        <div className="pl-lg-4">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group focused">
                                        <label className="form-control-label" for="surgeon" >Surgeon</label>
                                        <input disabled={true} type="text" id="surgeon" name="surgeon" className="form-control form-control-alternative" placeholder="Surgeon" value={inputText.surgeon} />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label className="form-control-label" for="surgicalGoal">Surgical Goal</label>
                                        <input disabled={true} type="text" id="surgicalGoal" name="surgicalGoal" className="form-control form-control-alternative" placeholder="Surgical Goal" value={inputText.surgicalGoal} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group focused">
                                        <label className="form-control-label" for="fueDeviceUsed">FUE Device Use</label>l
                                        <input disabled={true} type="text" id="fueDeviceUsed" name="fueDeviceUsed" className="form-control form-control-alternative" placeholder="FUE Device Use" value={inputText.fueDeviceUsed} />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group focused">
                                        <label className="form-control-label" for="fueTipStyle">FUE Tip Style</label>
                                        <input disabled={true} type="text" id="fueTipStyle" name="fueTipStyle" className="form-control form-control-alternative" placeholder="FUE Tip Style" value={inputText.fueTipStyle} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group focused">
                                        <label className="form-control-label" for="fueTipSize">FUE Tip Size</label>
                                        <input disabled={true} type="text" id="fueTipSize" name="fueTipSize" className="form-control form-control-alternative" placeholder="FUE Tip Size" value={inputText.fueTipSize} />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group focused">
                                        <label className="form-control-label" for="holdingSolution">Holding Solution</label>
                                        <input disabled={true} type="text" id="holdingSolution" name="holdingSolution" className="form-control form-control-alternative" placeholder="Holding Solution" value={inputText.holdingSolution} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group focused">
                                        <label className="form-control-label" for="placingDeviceUsed">Placing Device Used</label>
                                        <input disabled={true} type="text" id="placingDeviceUsed" name="placingDeviceUsed" className="form-control form-control-alternative" placeholder="Placing Device Used" value={inputText.placingDeviceUsed} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>}

                    {view === 2 && <>
                        <div className="pl-lg-4">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group focused">
                                        <label className="form-control-label" for="surgeon" >Surgeon</label>
                                        <Select className="form-control form-control-alternative"
                                            id="surgeon"
                                            onChange={handle.changeInput}
                                            value={inputText?.surgeon}
                                            label="Surgeon"
                                            options={deviceSurgeonList}
                                        />
                                        {/* <input type="text" name="surgeon" id="surgeon" className="form-control form-control-alternative" placeholder="Surgeon" onChange={handle.changeInput} value={inputText.surgeon} /> */}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label className="form-control-label" for="surgicalGoal">Surgical Goal</label>
                                        <Select className="form-control form-control-alternative"
                                            id="surgicalGoal"
                                            onChange={handle.changeInput}
                                            value={inputText?.surgicalGoal}
                                            label="Surgical Goal"
                                            options={surgeryGoalsList}
                                        />
                                        {/* <input type="text" name="surgicalGoal" id="surgicalGoal" className="form-control form-control-alternative" placeholder="Surgical Goal" onChange={handle.changeInput} value={inputText.surgicalGoal} /> */}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group focused">
                                        <label className="form-control-label" for="fueDeviceUsed">FUE Device Use</label>
                                        <Select className="form-control form-control-alternative"
                                            id="fueDeviceUsed"
                                            onChange={handle.changeInput}
                                            value={inputText?.fueDeviceUsed}
                                            label="FUE Device Use"
                                            options={deviceUsedList}
                                        />
                                        {/* <input type="text" name="fueDeviceUsed" id="fueDeviceUsed" className="form-control form-control-alternative" placeholder="FUE Device Use" onChange={handle.changeInput} value={inputText.fueDeviceUsed} /> */}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group focused">
                                        <label className="form-control-label" for="fueTipStyle">FUE Tip Style</label>
                                        <Select className="form-control form-control-alternative"
                                            id="fueTipStyle"
                                            onChange={handle.changeInput}
                                            value={inputText?.fueTipStyle}
                                            label="FUE Tip Style"
                                            options={tipStyleList}
                                        />
                                        {/* <input type="text" name="fueTipStyle" id="fueTipStyle" className="form-control form-control-alternative" placeholder="FUE Tip Style" onChange={handle.changeInput} value={inputText.fueTipStyle} /> */}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group focused">
                                        <label className="form-control-label" for="fueTipSize">FUE Tip Size</label>
                                        <Select className="form-control form-control-alternative"
                                            id="fueTipSize"
                                            onChange={handle.changeInput}
                                            value={inputText?.fueTipSize}
                                            label="FUE Tip Size"
                                            options={tipSizeList}
                                        />
                                        {/* <input type="text" name="fueTipSize" id="fueTipSize" className="form-control form-control-alternative" placeholder="FUE Tip Size" onChange={handle.changeInput} value={inputText.fueTipSize} /> */}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group focused">
                                        <label className="form-control-label" for="holdingSolution">Holding Solution</label>
                                        <Select className="form-control form-control-alternative"
                                            id="holdingSolution"
                                            onChange={handle.changeInput}
                                            value={inputText?.holdingSolution}
                                            label="Holding Solution"
                                            options={holdingSolutionList}
                                        />
                                        {/* <input type="text" name="holdingSolution" id="holdingSolution" className="form-control form-control-alternative" placeholder="Holding Solution" onChange={handle.changeInput} value={inputText.holdingSolution} /> */}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group focused">
                                        <label className="form-control-label" for="fueDeviceUsed">Placing Device Use</label>
                                        <Select className="form-control form-control-alternative"
                                            id="placingDeviceUsed"
                                            onChange={handle.changeInput}
                                            value={inputText?.placingDeviceUsed}
                                            label="Placing Device Used"
                                            options={placingDeviceUsedList}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>}



                    <hr className="my-4" />
                    {viewText[view].prev1 && <a className="btn d-inline-flex btn-sm btn-primary mx-1" onClick={e => handle.changeView(1)}>
                        <span>{viewText[view].prev1}</span>
                    </a>}

                    {viewText[view].prev2 && <a className="btn d-inline-flex btn-sm btn-primary mx-1" onClick={e => handle.changeView(2)}>
                        <span>{viewText[view].prev2}</span>
                    </a>}

                    <a className="btn d-inline-flex btn-sm btn-primary mx-1" onClick={e => handle.changeView(view + 1)}>
                        <span>{viewText[view].next}</span>
                    </a>
                </>}


            </div>
        </>
    );
};

export default PatiantDetail;